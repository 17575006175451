const CourseNameTranslationsMap = {
    "DeutschAMultimediaTest2": {
        "default": "Testkurs",
        "en": "Test Course",
        "tr": "Deneme Kursu"
    },
    "DeutschBVBBeginner": { "default": "German 1 - Beginner" },
    "DeutschBVBElementary": { "default": "German 2 - Elementary" },
    "DeutschBVBPim": { "default": "German 3 - Pre-Intermediate" },
    "DeutschBVBTop100Football": { "default": "German - Football Vocabulary" },
    "DeutschBauInnungAU": {
        "ar": "صناعة البناء AT",
        "bg": "Строителна индустрия AT",
        "bs": "Građevinska industrija AT",
        "cz": "Stavebnictví AT",
        "default": "Bau AT",
        "en": "Construction industry AT",
        "fa": "صنعت ساخت و ساز AT",
        "hr": "Građevinska industrija AT",
        "it": "Industria edile AT",
        "pl": "Przemysł budowlany AT",
        "pt": "Indústria de construção AT",
        "ro": "Industria construcțiilor AT",
        "sk": "Stavebníctvo AT",
        "sl": "Gradbena industrija AT",
        "sr": "Građevinska industrija AT",
        "tr": "İnşaat sektörü AT",
        "uk": "Будівельна галузь АТ"
    },
    "DeutschBeginner": {
        "ar": "الألمانية - مُبتدىء 1",
        "bg": "Немски 1 - Начинаещ",
        "br": "Alemão 1 - Iniciante",
        "ca": "Alemany 1 - Principiant",
        "cz": "Německy 1 - Začátečník",
        "de": "Deutsch Anfänger",
        "default": "German 1 - Beginner",
        "el": "Γερμανικά 1 - Αρχάριος",
        "en": "German 1 - Beginner",
        "er": "Alemán 1 - Principiante",
        "es": "Alemán 1 - Principiante",
        "ex": "Alemán 1 - Principiante",
        "fa": "1 آلمانی - مبتدی",
        "fr": "Allemand 1 - Débutant",
        "hi": "जर्मन 1 - नौसिखिया",
        "hr": "Njemački 1 - Početnik",
        "hu": "Német 1 - Kezdő",
        "hy": "Գերմաներեն 1 - Սկսնակ",
        "it": "Tedesco 1 - Principiante",
        "ja": "ドイツ語 1 - 初心者",
        "ko": "독일어 1 - 초심자",
        "lt": "Vokiečių k. 1 - Pradedantysis",
        "nl": "Duitse 1 - Beginner",
        "no": "Tysk 1 - Begynner",
        "pl": "Niemiecki 1 - Początkujący",
        "pt": "Alemão 1 - Principiante",
        "ro": "Germană 1 - Începător",
        "ru": "Немецкий 1 - Начинающий",
        "rw": "Ikidage 1 - Umutangizi",
        "sk": "Nemčina 1 - Začiatočník",
        "sl": "Nemščina 1 - Začetnik",
        "sr": "Nemački 1  - Početni",
        "sv": "Tysk 1 - Nybörjare",
        "sw": "Kijeremani 1 - Ayeanza",
        "tr": "Almanca 1 - Başlangıç",
        "uk": "Німецька 1 - Початківець",
        "ur": "1 جرمن - نوآموز",
        "zh": "德语 1 - 新手"
    },
    "DeutschBerufeDummyKursFuerKursbuchbasierendeCommunities": {
        "default": "Willkommen!"
    },
    "DeutschConyCamBusfahrer": { "default": "Fleoo" },
    "DeutschDeutschAAPublishTest": {
        "ar": "Marhaba",
        "de": "Hallo",
        "default": "Hiiii",
        "es": "Hola",
        "fr": "Salut",
        "it": "Ciao",
        "ja": "Konichiwa",
        "pl": "Strastvitje",
        "pt": "Salud",
        "ru": "Poka",
        "tr": "Selam"
    },
    "DeutschFootball": {
        "ar": " الألمانية - كرة القدم",
        "bg": "Немски - Футбол",
        "br": "Alemão - Futebol",
        "cz": "Německy - Fotbal",
        "default": "German - Football",
        "el": "Γερμανικά - Ποδόσφαιρο",
        "en": "German - Football",
        "er": "Alemán - Fútbol",
        "es": "Alemán - Fútbol",
        "ex": "Alemán - Futbol",
        "fa": "آلمانی-  فوتبال",
        "fr": "Allemand - Football",
        "hi": "जर्मन - फुटबॉल",
        "hr": "Njemački - Nogomet",
        "hu": "Német - Futball",
        "hy": "Գերմաներեն - Ֆուտբոլ",
        "it": "Tedesco - Calcio",
        "ja": "ドイツ語 - サッカー",
        "ko": "독일어 - 축구",
        "lt": "Vokiečių k. - Futbolas",
        "no": "Tysk - Fotball",
        "pl": "Niemiecki - piłka nożna",
        "pt": "Alemão - Futebol",
        "ro": "Germană - Fotbal",
        "ru": "Немецкий - Футбол",
        "rw": "Ikidage - Umupira w'amaguru",
        "sk": "Nemčina - Futbal",
        "sl": "Nemščina - Nogomet",
        "sr": "Nemački - fudbal",
        "sv": "Tysk - Fotboll",
        "sw": "Kijeremani - Kandanda",
        "tr": "Almanca - Futbol",
        "ur": "جرمن - فٹ بال",
        "zh": "德语 - 足球"
    },
    "DeutschGruWoCoolista": {
        "ar": "مفردات اساسية - الألمانية",
        "bg": "Основен речник - Немски",
        "ca": "Vocabulari bàsic - Alemany",
        "cz": "Základní slovní zásoba - Německy",
        "default": "Basic vocabulary - German",
        "el": "Βασικό λεξιλόγιο - Γερμανικά",
        "en": "Basic vocabulary - German",
        "es": "Vocabulario básico - Alemán",
        "fa": "واژگان پایه - آلمانی",
        "fr": "Vocabulaire de base - Allemand",
        "hr": "Osnovni vokabular - Njemački",
        "hu": "Alap szókincs - Német",
        "it": "Lessico di base - Tedesco",
        "lt": "Pagrindinis žodynėlis - Vokiečių k.",
        "nl": "Basiswoordenschat - Duits",
        "pl": "Słownictwo podstawowe - Niemiecki",
        "pt": "Vocabulário básico - Alemão",
        "ro": "Vocabularul de bază - Germană",
        "ru": "Базовая лексика - Немецкий",
        "sk": "Základná slovná zásoba - Nemčina",
        "sl": "Osnovni besednjak - Nemščina",
        "sq": "Fjalori Basic - Gjermanisht",
        "sr": "Osnovni rečnik - Nemačko",
        "tr": "Basit kelime bilgisi - Almanca",
        "uk": "Словник базовий - Німецько"
    },
    "DeutschGruWoStarter": {
        "ar": "مفردات اساسية 1 - الألمانية",
        "bg": "Основен речник 1 - Немски",
        "ca": "Vocabulari bàsic 1 - Alemany",
        "cz": "Základní slovní zásoba 1 - Německy",
        "default": "Grundwortschatz 1 - Deutsch",
        "el": "Βασικό λεξιλόγιο 1 - Γερμανικά",
        "en": "Basic vocabulary 1 - German",
        "es": "Vocabulario básico 1 - Alemán",
        "fa": "واژگان پایه 1 - آلمانی",
        "fr": "Vocabulaire de base 1 - Allemand",
        "hr": "Osnovni vokabular 1 - Njemački",
        "hu": "Alap szókincs 1 - Német",
        "it": "Lessico di base 1 - Tedesco",
        "lt": "Pagrindinis žodynėlis 1 - Vokiečių k.",
        "nl": "Basiswoordenschat 1 - Duits",
        "pl": "Słownictwo podstawowe 1 - Niemiecki",
        "pt": "Vocabulário básico 1 - Alemão",
        "ro": "Vocabularul de bază 1 - Germană",
        "ru": "Базовая лексика 1 - Немецкий",
        "sk": "Základná slovná zásoba 1 - Nemčina",
        "sl": "Osnovni besednjak 1 - Nemščina",
        "sq": "Fjalori Basic 1 - Gjermanisht",
        "sr": "Osnovni rečnik 1 - Nemačko",
        "tr": "Basit kelime bilgisi 1 - Almanca",
        "uk": "Словник базовий 1 - Німецько"
    },
    "DeutschGruwo": {
        "ar": "مفردات اساسية - الألمانية",
        "bg": "Основен речник - Немски",
        "br": "Vocabulário básico - Alemão",
        "ca": "Vocabulari bàsic - Alemany",
        "cz": "Základní slovní zásoba - Německy",
        "default": "Basic vocabulary - German",
        "el": "Βασικό λεξιλόγιο - Γερμανικά",
        "en": "Basic vocabulary - German",
        "er": "Vocabulario básico - Alemán",
        "es": "Vocabulario básico - Alemán",
        "ex": "Vocabulario básico - Alemán",
        "fa": "واژگان پایه - آلمانی",
        "fr": "Vocabulaire de base - Allemand",
        "hi": "बुनियादी शब्दावली - जर्मन",
        "hr": "Osnovni vokabular - Njemački",
        "hu": "Alap szókincs - Német",
        "hy": "հիմնական բառապաշար - Գերմաներեն",
        "it": "Lessico di base - Tedesco",
        "ja": "基本語彙 - ドイツ語",
        "ko": "기본 어휘 - 독일어",
        "lt": "Pagrindinis žodynėlis - Vokiečių k.",
        "no": "Grunnleggende ordforråd - Tysk",
        "pl": "Słownictwo podstawowe - Niemiecki",
        "pt": "Vocabulário básico - Alemão",
        "ro": "Vocabularul de bază - Germană",
        "ru": "Базовая лексика - Немецкий",
        "sk": "Základná slovná zásoba - Nemčina",
        "sl": "Osnovni besednjak - Nemščina",
        "sq": "Fjalori Basic - Gjermanisht",
        "sr": "Osnovni rečnik - Nemačko",
        "sw": "Msamiati wa msingi - Kijeremani",
        "tr": "Basit kelime bilgisi - Almanca",
        "ur": "بنیادی الفاظ - جرمن",
        "zh": "基本词汇 - 德语"
    },
    "DeutschGruwoFortgeschritten": {
        "ar": "مفردات اساسية 3 - الألمانية",
        "bg": "Основен речник 3 - Немски",
        "ca": "Vocabulari bàsic 3 - Alemany",
        "cz": "Základní slovní zásoba 3 - Německy",
        "default": "Grundwortschatz 3 - Deutsch",
        "el": "Βασικό λεξιλόγιο 3 - Γερμανικά",
        "en": "Basic vocabulary 3 - German",
        "es": "Vocabulario básico 3 - Alemán",
        "fa": "واژگان پایه 3 - آلمانی",
        "fr": "Vocabulaire de base 3 - Allemand",
        "hr": "Osnovni vokabular 3 - Njemački",
        "hu": "Alap szókincs 3 - Német",
        "it": "Lessico di base 3 - Tedesco",
        "lt": "Pagrindinis žodynėlis 3 - Vokiečių k.",
        "nl": "Basiswoordenschat 3 - Duits",
        "pl": "Słownictwo podstawowe 3 - Niemiecki",
        "pt": "Vocabulário básico 3 - Alemão",
        "ro": "Vocabularul de bază 3 - Germană",
        "ru": "Базовая лексика 3 - Немецкий",
        "sk": "Základná slovná zásoba 3 - Nemčina",
        "sl": "Osnovni besednjak 3 - Nemščina",
        "sq": "Fjalori Basic 3 - Gjermanisht",
        "sr": "Osnovni rečnik 3 - Nemačko",
        "tr": "Basit kelime bilgisi 3 - Almanca",
        "uk": "Словник базовий 3 - Німецько"
    },
    "DeutschGruwoMittel": {
        "ar": "مفردات اساسية 2 - الألمانية",
        "bg": "Основен речник 2 - Немски",
        "ca": "Vocabulari bàsic 2 - Alemany",
        "cz": "Základní slovní zásoba 2 - Německy",
        "default": "Grundwortschatz 2 - Deutsch",
        "el": "Βασικό λεξιλόγιο 2 - Γερμανικά",
        "en": "Basic vocabulary 2 - German",
        "es": "Vocabulario básico 2 - Alemán",
        "fa": "واژگان پایه 2 - آلمانی",
        "fr": "Vocabulaire de base 2 - Allemand",
        "hr": "Osnovni vokabular 2 - Njemački",
        "hu": "Alap szókincs 2 - Német",
        "it": "Lessico di base 2 - Tedesco",
        "lt": "Pagrindinis žodynėlis 2 - Vokiečių k.",
        "nl": "Basiswoordenschat 2 - Duits",
        "pl": "Słownictwo podstawowe 2 - Niemiecki",
        "pt": "Vocabulário básico 2 - Alemão",
        "ro": "Vocabularul de bază 2 - Germană",
        "ru": "Базовая лексика 2 - Немецкий",
        "sk": "Základná slovná zásoba 2 - Nemčina",
        "sl": "Osnovni besednjak 2 - Nemščina",
        "sq": "Fjalori Basic 2 - Gjermanisht",
        "sr": "Osnovni rečnik 2 - Nemačko",
        "tr": "Basit kelime bilgisi 2 - Almanca",
        "uk": "Словник базовий 2 - Німецько"
    },
    "DeutschH2academyprototyp": {
        "default": "Komptech H2’s Technikwortschatz",
        "en": "Komptech H2’s Technical vocabulary"
    },
    "DeutschHotelDemoKroatischTopicsABC": {
        "default": "Hotel + Restaurant Demo de-hr"
    },
    "DeutschHotellerieDemocontent": {
        "ar": "الفنادق - التجريبي",
        "default": "Hotels - Demo",
        "en": "Hotels - Demo",
        "fr": "Hôtels - Démo",
        "hr": "Hoteli - Demo",
        "it": "Hotel - Demo"
    },
    "DeutschHotellerieKomplett": {
        "default": "Hotellerie und Gastronomie (Deutsch)",
        "en": "Hotel industry and Gastronomy (German)",
        "es": "Industria hotelera y Gastronomía (Alemán)",
        "fr": "Industrie hôtelière et Gastronomie (Allemand)",
        "it": "Industria alberghiera e Gastronomia (Tedesco)"
    },
    "DeutschHotellerieNurGastronomie": {
        "default": "Gastronomie (Deutsch)",
        "en": "Gastronomy (German)",
        "es": "Gastronomía (Alemán)",
        "fr": "Gastronomie (Allemand)",
        "it": "Gastronomia (Tedesco)"
    },
    "DeutschHotellerieNurHotel": {
        "default": "Hotellerie (Deutsch)",
        "en": "Hotel industry (German)",
        "es": "Industria hotelera (Alemán)",
        "fr": "Industrie hôtelière (Allemand)",
        "it": "Industria alberghiera (Tedesco)"
    },
    "DeutschIntermediate": {
        "ar": "2 الألمانية - اساسي",
        "bg": "Немски 2 - Елементарно",
        "br": "Alemão 2 - Básico",
        "ca": "Alemany 2 - Elemental",
        "cz": "Německy 2 - Pokročilý začátečník",
        "default": "German 2 - Elementary",
        "el": "Γερμανικά 2 - Στοιχειώδης",
        "en": "German 2 - Elementary",
        "er": "Alemán 2 - Elemental",
        "es": "Alemán 2 - Elemental",
        "ex": "Alemán 2 - Elemental",
        "fa": "2 آلمانی- مقدماتی",
        "fr": "Allemand 2 - Élémentaire",
        "hi": "जर्मन 2 - प्राथमिक",
        "hr": "Njemački 2 - Osnovni",
        "hu": "Német 2 - Alapszint",
        "hy": "Գերմաներեն 2 - Տարրական",
        "it": "Tedesco 2 - Elementare",
        "ja": "ドイツ語 2 - 初級者",
        "ko": "독일어 2 - 초급",
        "lt": "Vokiečių k. 2 - Pradinis",
        "nl": "Duitse 2 - Elementair",
        "no": "Tysk 2 - Lett",
        "pl": "Niemiecki 2 - Podstawowy",
        "pt": "Alemão 2 - Elementar",
        "ro": "Germană 2 - Elementar",
        "ru": "Немецкий 2 - Элементарный уровень",
        "rw": "Ikidage 2 - Uwo ku rugero rwo hasi",
        "sk": "Nemčina 2 - Elementárna úroveň",
        "sl": "Nemščina 2 - Osnovno znanje",
        "sr": "Nemački 2 - Osnovni",
        "sv": "Tysk 2 - Elementärt",
        "sw": "Kijeremani 2 - Ya msingi",
        "tr": "Almanca 2 - Temel",
        "uk": "Німецька 2 - Елементарно",
        "ur": "2 جرمن - ابتدائی",
        "zh": "德语 2 - 初級"
    },
    "DeutschKlinikpflegeDemocontent": {
        "default": "Klinikpflege",
        "en": "Hospital care",
        "pl": "Opieka Szpitalna"
    },
    "DeutschKlinikpflegeHueber": {
        "ar": "الألمانية في الرعاية",
        "bg": "Немски език в сестринството",
        "default": "Deutsch in der Pflege",
        "en": "German in nursing care",
        "es": "Alemán en enfermería",
        "fr": "L'allemand dans les soins infirmiers",
        "hr": "Nijemac u sestrinstvu",
        "hu": " Német az ápolásban",
        "it": "Tedesco in assistenza infermieristica",
        "nl": "Duits in de verpleging",
        "pl": "Niemiecki w opiece pielęgniarskiej",
        "pt": "Alemão em cuidados de enfermagem",
        "ro": "Germană la alăptare",
        "ru": "Немецкий в уходе",
        "sk": "Nemčina v ošetrovateľstve",
        "sr": "Nemac u sestrinstvu"
    },
    "DeutschLinara": {
        "ar": " الألمانية - رعاية منزلية",
        "bg": "Домашни грижи - Немски",
        "cz": "Domácí péče - Německy",
        "default": "Heimpflege - Deutsch",
        "en": "Home care - German",
        "es": "Cuidados en el hogar - Alemán",
        "fr": "Soins à domicile - Allemand",
        "hr": "Kućna njega - Njemački",
        "hu": "Otthoni ápolás - Német",
        "it": "Assistenza domiciliare - Tedesco",
        "lt": "Globos namai - Vokiečių k.",
        "pl": "Opieka domowa - Niemiecki",
        "ro": "Ingrijirea casei - Germană",
        "ru": "Домашний уход - Немецкий",
        "sk": "Domáca starostlivosť - Nemčina",
        "sl": "Nega na domu - Nemščina",
        "sr": "Kućna nega - Nemačko",
        "tr": "Evde bakım - Almanca",
        "uk": "Домашній догляд - Німецько",
        "zh": "家庭护理 - 德语"
    },
    "DeutschPIM": {
        "ar": "3 الألمانية - قبل المتوسط",
        "bg": "Немски 3 - Предварителен междинен",
        "br": "Alemão 3 - Pré-intermediário",
        "ca": "Alemany 3 - Pre-intermedi",
        "cz": "Německy 3 - Mírně pokročilý",
        "default": "German 3 - Pre-intermediate",
        "el": "Γερμανικά 3 - Προ-ενδιάμεσο",
        "en": "German 3 - Pre-intermediate",
        "er": "Alemán 3 - Preintermedio",
        "es": "Alemán 3 - Preintermedio",
        "ex": "Alemán 3 - Pre-intermedio",
        "fa": "3 آلمانی - متوسط رو به پایین",
        "fr": "Allemand 3 - Pré-intermédiaire",
        "hi": "जर्मन 3 - पूर्व मध्यवर्ती",
        "hr": "Njemački 3 - Pred-srednji",
        "hu": "Német 3 - Középhaladó",
        "hy": "Գերմաներեն 3 - Նախամիջին",
        "it": "Tedesco 3 - Pre-intermedio",
        "ja": "ドイツ語 3 - 中級下",
        "ko": "독일어 3 - 중하급",
        "lt": "Vokiečių k. 3 - Žemesnis nei vidutinis",
        "nl": "Duitse 3 - Pre-intermediate",
        "no": "Tysk 3 - Pre-mellomliggende",
        "pl": "Niemiecki 3 - Niższy średnio zaawansowany",
        "pt": "Alemão 3 - Pré-intermédio",
        "ro": "Germană 3 - Pre-intermediar",
        "ru": "Немецкий 3 - Ниже среднего",
        "rw": "Ikidage 3 - Ikibanziriza icyiciro cyo hagati",
        "sk": "Nemčina 3 - Mierne pokročilý",
        "sl": "Nemščina 3 - Nižji-srednji",
        "sr": "Nemački 3 - Niži-srednji",
        "sv": "Tysk 3 - Pre-mellanprodukt",
        "sw": "Kijeremani 3 - Kabla ya ya kati",
        "tr": "Almanca 3 - Alt orta seviye",
        "uk": "Німецька 3 - Попередній проміжний",
        "ur": "3 جرمن - قبل از وسط",
        "zh": "德语 3 - 预中间"
    },
    "DeutschPersonal": {
        "de": "Mein persönliches Deutsch",
        "default": "Mein persönliches Deutsch"
    },
    "DeutschReinigung": {
        "ar": "خدمات تنظيف المباني - الألمانية",
        "bg": "Почистване на сгради - Немски",
        "cz": "Úklid budov  - Německy",
        "default": "Facility cleaning - German",
        "en": "Facility cleaning - German",
        "es": "Limpieza industrial - Alemán",
        "fa": "نظافت ساختمان - آلمانی",
        "fr": "Nettoyage des bâtiments - Allemand",
        "hr": "Čišćenje zgrada - Njemački",
        "hu": "Épület takarítás - Német",
        "pl": "Sprzątanie budynku - Niemiecki",
        "ro": "Curățenia în clădire - Germană",
        "ru": "Уборка зданий - Немецкий",
        "sk": "Čistenie budov - Nemčina",
        "sl": "Čiščenje zgradb - Nemščina",
        "sr": "Čišćenje zgrada - Nemačko",
        "tr": "Bina temizliği - Almanca"
    },
    "DeutschReinigungAT": {
        "ar": "خدمات تنظيف المباني - الألمانية",
        "bg": "Почистване на сгради - Немски",
        "cz": "Úklid budov  - Německy",
        "default": "Facility cleaning - German",
        "en": "Facility cleaning - German",
        "es": "Limpieza industrial - Alemán",
        "fa": "نظافت ساختمان - آلمانی",
        "fr": "Nettoyage des bâtiments - Allemand",
        "hr": "Čišćenje zgrada - Njemački",
        "hu": "Épület takarítás - Német",
        "pl": "Sprzątanie budynku - Niemiecki",
        "ro": "Curățenia în clădire - Germană",
        "ru": "Уборка зданий - Немецкий",
        "sk": "Čistenie budov - Nemčina",
        "sl": "Čiščenje zgradb - Nemščina",
        "sr": "Čišćenje zgrada - Nemačko",
        "tr": "Bina temizliği - Almanca"
    },
    "DeutschReinigungATDemoFuerst": {
        "default": "DEMO Gebäudereinigung - Deutsch",
        "en": "DEMO Facility cleaning - German",
        "hr": "DEMO Čišćenje zgrada - Njemački",
        "hu": "DEMO Épület takarítás - Német",
        "pl": "DEMO Sprzątanie budynku - Niemiecki"
    },
    "DeutschReinigungAT_Schulungsvideos": {
        "ar": "فيديوهات تعليمية",
        "bg": "Видео ръководства",
        "cz": "Školící videa",
        "default": "Schulungsvideos",
        "en": "Video tutorials",
        "es": "Videotutoriales",
        "fa": "آموزه های تصویری",
        "fr": "Tutoriels vidéos",
        "hr": "Video tutorijali",
        "hu": "Okatóvideók",
        "nl": "Trainingsvideo's",
        "pl": "Filmy szkoleniowe",
        "ro": "Tutoriale video",
        "ru": "Обучающие видео",
        "sk": "Videotutoriály",
        "sl": "Video navodila",
        "sq": "Video Ndihmëse",
        "sr": "Video tutorijali",
        "tr": "Videolu Eğitimler"
    },
    "DeutschReinigungMyTraining": {
        "default": "Gebäudereinigung - mytraining.at"
    },
    "DeutschTestBauInnungBosnian": { "default": "TestBauInnungBosnian" },
    "DeutschUARS_Gruwo": {
        "default": "Grundwortschatz",
        "ru": "Базовая лексика - Немецкий",
        "uk": "Словник базовий - Німецько"
    },
    "DeutschUA_Gruwo": {
        "default": "German - Basic Vocabulary",
        "uk": "Німецько - Словник базовий"
    },
    "DeutschUA_HalloErwachsene": {
        "default": "Hallo Erwachsene",
        "uk": "Привіт, дорослі!"
    },
    "DeutschUA_HalloKinder": {
        "default": "Hallo Kinder",
        "uk": "Привіт, діти!"
    },
    "DeutschUA_LMBeginnerA1": {
        "default": "German 1 - Beginner",
        "uk": "Німецька 1 - Початківець"
    },
    "DeutschUA_LMBeginnerA1BVB": {
        "default": "1 German - Beginner",
        "uk": "1 Німецька - Початківець"
    },
    "DeutschUA_LMElementaryA2_1": {
        "default": "German 2 - Elementary",
        "uk": "Німецька 2 - Елементарно"
    },
    "DeutschUA_LMElementaryA2_1_BVB": {
        "default": "2 German - Elementary",
        "uk": "2 Німецька - Елементарно"
    },
    "DeutschUA_LMFootball": { "default": "Fußball", "uk": "Футбол" },
    "DeutschUA_LMFootball_V2": { "default": "Football", "uk": "Футбол" },
    "DeutschUA_LMPre-IntermediateA2_2": {
        "default": "German 3 - Pre-Intermediate",
        "uk": "Німецька 3 - Попередній проміжний"
    },
    "DeutschUA_LMPre-IntermediateA2_2_BVB": {
        "default": "3 German - Pre-Intermediate",
        "uk": "3 Німецька - Попередній проміжний"
    },
    "EnglishAwoAufbauwortschatz": {
        "br": "Inglês - Vocabulário avançado",
        "ca": "Anglès - Vocabulari avançat",
        "cz": "Angličtina - Pokročilá slovní zásoba",
        "de": "Englisch - Aufbauwortschatz",
        "default": "English - Advanced Vocabulary",
        "er": "Inglés - Vocabulario avanzado",
        "es": "Inglés - Vocabulario avanzado",
        "ex": "Inglés - Vocabulario avanzado",
        "fr": "Anglais - Vocabulaire avancé",
        "hr": "Engleski - Napredni rječnik",
        "hu": "Angol - Haladó szókincs",
        "it": "Inglese - Vocabolario avanzato",
        "ja": "英語 - 上級者向け語彙",
        "ko": "영어 - 고급 어휘",
        "nl": "Engels - Geavanceerd vocabulaire",
        "pl": "Angielski - Słownictwo zaawansowane",
        "pt": "Inglês - Vocabulário avançado",
        "ro": "Engleză - Vocabular avansat",
        "ru": "Английский - Расширенный словарный запас",
        "sk": "Angličtina - Slovník pre pokročilých",
        "sl": "Angleščina - Napreden besedni zaklad",
        "sr": "Engleski  - Napredni rečnik",
        "tr": "İngilizce - Ileri kelime hazinesi",
        "zh": "英文 - 高级词汇"
    },
    "EnglishBVBBeginner": { "default": "English 1 - Beginner" },
    "EnglishBVBElementary": { "default": "English 2 - Elementary" },
    "EnglishBVBPim": { "default": "English 3 - Pre-Intermediate" },
    "EnglishBVBTop100Football": { "default": "English - Football Vocabulary" },
    "EnglishBeginner": { "default": "British English 1 - Beginner" },
    "EnglishBeginnerAE": { "default": "American English 1 - Beginner" },
    "EnglishBerlitz_PhonicsJump": {
        "default": "Berlitz Phonics Jump",
        "jj": "Berlitz Phonics Jump"
    },
    "EnglishDemoBVBFootballTop100": { "default": "English4Football Demo" },
    "EnglishDemoELTSongs": { "default": "English4Kids Demo" },
    "EnglishDemoEnglishA1": { "default": "English4Beginners Demo" },
    "EnglishDemo_BerlitzPhonicsJump": {
        "default": "Demo Berlitz Phonics Jump "
    },
    "EnglishDemo_ModelAcademy": { "default": "Demo Model Academy" },
    "EnglishDemo_OxfordBusinessResult_Starter_inklVideos": {
        "default": "Demo Business Result - Starter"
    },
    "EnglishDemo_Translex1000": { "default": "old_Demo" },
    "EnglishDemo_Translex1000_v2": { "default": "Demo Translex 1000" },
    "EnglishEDVECadvanced1000": {
        "default": "English Advanced 1000",
        "ja": "English Advanced 1000"
    },
    "EnglishEDVECbasic300": {
        "default": "English Basic 300",
        "ja": "English Basic 300"
    },
    "EnglishEDVECintermediate600": {
        "default": "English Intermediate 600",
        "ja": "English Intermediate 600"
    },
    "EnglishEDVECkentei4": {
        "default": "OK! Kentei Grade4",
        "ja": "OK! Kentei Grade4"
    },
    "EnglishELTSongs_Starter1": {
        "default": "English4Kids by Planet Pop 1 (Units 1 - 29)"
    },
    "EnglishELTSongs_Starter2": {
        "default": "English4Kids by Planet Pop 2 (Units 30 - 58)"
    },
    "EnglishEdvecKNTdemo": { "default": "KNT", "ja": "KNT" },
    "EnglishEdvecOlympicsAdvanced": {
        "ar": "English for the Tokyo Games -Advanced-",
        "de": "English for the Tokyo Games -Advanced-",
        "default": "English for the Tokyo Games -Advanced-",
        "fr": "English for the Tokyo Games -Advanced-",
        "ja": "English for the Tokyo Games -Advanced-"
    },
    "EnglishEdvecOlympicsBasic": {
        "ar": "English for the Tokyo Games -Basic-",
        "de": "English for the Tokyo Games -Basic-",
        "default": "English for the Tokyo Games -Basic-",
        "fr": "English for the Tokyo Games -Basic-",
        "ja": "English for the Tokyo Games -Basic-"
    },
    "EnglishEnglish4Holiday": { "default": "English4Holiday" },
    "EnglishEnglish4HolidayCoolista": {
        "ar": "English4Holiday",
        "de": "English4Holiday",
        "default": "English4Holiday",
        "es": "English4Holiday",
        "fr": "English4Holiday",
        "it": "English4Holiday",
        "pl": "English4Holiday",
        "pt": "English4Holiday",
        "ru": "English4Holiday",
        "tr": "English4Holiday"
    },
    "EnglishFootball": {
        "ar": "الإنجليزية - كرة القدم",
        "bg": "Английски - Футбол",
        "br": "Inglês - Futebol",
        "cz": "Angličtina - Fotbal",
        "de": "Englisch - Fußball",
        "default": "English - Football",
        "el": "Αγγλικά - Ποδόσφαιρο",
        "er": "Inglés - Fútbol",
        "es": "Inglés - Fútbol",
        "ex": "Inglés - Futbol",
        "fa": "Inglés - فوتبال",
        "fr": "Anglais - Football",
        "hi": "अंग्रेजी - फुटबॉल",
        "hr": "Engleski - Nogomet",
        "hu": "Angol - Futball",
        "hy": "Անգլերեն - Ֆուտբոլ",
        "it": "Inglese - Calcio",
        "ja": "英語 - サッカー",
        "ko": "영어 - 축구",
        "lt": "Anglų k. - Futbolas",
        "no": "Engelsk - Fotball",
        "pl": "Angielski - Piłka nożna",
        "pt": "Inglês - Futebol",
        "ro": "Engleză - Fotbal",
        "ru": "Английский - Футбол",
        "rw": "Icyongereza - Umupira w'amaguru",
        "sk": "Angličtina - Futbal",
        "sl": "Angleščina - Nogomet",
        "sr": "Engleski - fudbal",
        "sv": "Engelska - Fotboll",
        "sw": "Kingereza - Kandanda",
        "tr": "İngilizce - Futbol",
        "ur": "انگریزی - فٹ بال",
        "zh": "英语 - 足球"
    },
    "EnglishGruWo1Beginner": { "default": "Basic Vocabulary 1 " },
    "EnglishGruWo2Intermediate": { "default": "Basic Vocabulary 2 " },
    "EnglishGruWo3Advanced": { "default": "Basic Vocabulary 3 " },
    "EnglishGruWoCoolista": {
        "ar": "مفردات اساسية - الإنجليزية",
        "bg": "Основен речник - Английски",
        "ca": "Vocabulari bàsic - Anglès",
        "cz": "Základní slovní zásoba - Česky",
        "de": "Grundwortschatz - Englisch",
        "default": "Basic vocabulary - English",
        "el": "Βασικό λεξιλόγιο - Αγγλικά",
        "es": "Vocabulario básico - Inglés",
        "fa": "واژگان پایه - انگلیسی",
        "fr": "Vocabulaire de base - Anglais",
        "hr": "Osnovni vokabular - Engleski",
        "hu": "Alap szókincs - Angol",
        "it": "Lessico di base - Inglese",
        "lt": "Pagrindinis žodynėlis - Anglų k.",
        "nl": "Basiswoordenschat - Engels",
        "pl": "Słownictwo podstawowe - Angielski",
        "pt": "Vocabulário básico - Inglês",
        "ro": "Vocabularul de bază - Engleză",
        "ru": "Базовая лексика - Английский",
        "sk": "Základná slovná zásoba - Angličtina",
        "sl": "Osnovni besednjak - Angleščina",
        "sq": "Fjalori Basic - Anglisht",
        "sr": "Osnovni rečnik - Engleski ",
        "tr": "Basit kelime bilgisi - İngilizce",
        "uk": "Словник базовий - Українсько"
    },
    "EnglishGruwo": {
        "ar": "مفردات اساسية - الإنجليزية",
        "bg": "Основен речник - Английски",
        "ca": "Vocabulari bàsic - Anglès",
        "cz": "Základní slovní zásoba - Angličtina",
        "de": "Grundwortschatz - Englisch 1",
        "default": "Basic vocabulary - English",
        "el": "Βασικό λεξιλόγιο - Αγγλικά",
        "es": "Vocabulario básico - Inglés",
        "fa": "واژگان پایه - انگلیسی",
        "fr": "Vocabulaire de base - Anglais",
        "hr": "Osnovni vokabular - Engleski",
        "hu": "Alap szókincs - Angol",
        "it": "Lessico di base - Inglese",
        "lt": "Pagrindinis žodynėlis - Anglų k.",
        "nl": "Basiswoordenschat - Engels",
        "pl": "Słownictwo podstawowe - Angielski",
        "pt": "Vocabulário básico - Inglês",
        "ro": "Vocabularul de bază - Engleză",
        "ru": "Базовая лексика - Английский",
        "sk": "Základná slovná zásoba - Angličtina",
        "sl": "Osnovni besednjak - Angleščina",
        "sq": "Fjalori Basic - Anglisht",
        "sr": "Osnovni rečnik - Engleski ",
        "tr": "Basit kelime bilgisi - İngilizce",
        "uk": "Словник базовий - Українсько"
    },
    "EnglishH2academyprototyp": {
        "de": "Komptech H2’s Technikwortschatz",
        "default": "Komptech H2’s Technical vocabulary"
    },
    "EnglishHotelIndustryComplete": {
        "de": "Hotellerie und Gastronomie (Englisch)",
        "default": "Hotel industry and Gastronomy (English)",
        "es": "Industria hotelera y Gastronomía (Inglés)",
        "fr": "Industrie hôtelière et Gastronomie (Anglais)",
        "it": "Industria alberghiera e Gastronomia (Inglese)"
    },
    "EnglishHotelIndustryOnlyHotel": {
        "de": "Hotellerie (Englisch)",
        "default": "Hotel industry (English)",
        "es": "Industria hotelera (Inglés)",
        "fr": "Industrie hôtelière (Anglais)",
        "it": "Industria alberghiera (Inglese)"
    },
    "EnglishHotelIndustryOnlyRestaurant": {
        "de": "Gastronomie (Englisch)",
        "default": "Gastronomy (English)",
        "es": "Gastronomía (Inglés)",
        "fr": "Gastronomie (Anglais)",
        "it": "Gastronomia (Inglese)"
    },
    "EnglishHotelindustryDemoLearntec": {
        "de": "Hotels - Demo Learntec",
        "default": "Hotels - Demo Learntec"
    },
    "EnglishHotelsDemo": { "de": "Hotels - Demo", "default": "Hotels - Demo" },
    "EnglishIntermediate": { "default": "British English 2 - Elementary" },
    "EnglishIntermediateAE": { "default": "American English 2 - Elementary" },
    "EnglishIntermediateB1": { "default": "British English 4 - Intermediate" },
    "EnglishIntermediateB1AE": {
        "default": "American English 4 - Intermediate"
    },
    "EnglishJapanEdvecSampleEnglish": { "default": "Edvec", "ja": "Edvec" },
    "EnglishKERNautomotive": {
        "de": "KERN Automotive",
        "default": "KERN Automotive"
    },
    "EnglishLMBeginner_FootballattheEnd_v1": {
        "ar": "1 الإنجليزية - مُبتدىء",
        "bg": "1 Английски - Начинаещ",
        "br": "1 Inglês - Iniciante",
        "ca": "1  Anglès - Principiant",
        "cz": "1 Anglicky - Začátečník",
        "de": "1 Englisch - Anfänger",
        "default": "1 English - Beginner",
        "el": "1 Αγγλικά - Αρχάριος",
        "er": "1 Inglés - Principiante",
        "es": "1 Inglés - Principiante",
        "ex": "1 Inglés - Principiante",
        "fa": "1 انگلیسی - مبتدی",
        "fr": "1 Anglais - Débutant",
        "hi": "1 अंग्रेज़ी - प्राथमिक",
        "hr": "1 Engleski - Početnik",
        "hu": "1 Angol - Kezdő",
        "hy": "1 Անգլերեն - Սկսնակ",
        "it": "1 Inglese - Principiante",
        "ja": "1 英語 - 初心者",
        "ko": "1 영어 - 초심자",
        "lt": "1 Anglų k. - Pradedantysis",
        "nl": "1 Engels - Beginner",
        "no": "1 Engelsk - Begynner",
        "pl": "1 Angielski - Początkujący",
        "pt": "1 Inglês - Principiante",
        "ro": "1 Engleză - Începător",
        "ru": "1 Английский - Начинающий",
        "rw": "1 Icyongereza - Umutangizi",
        "sk": " 1 Angličtina - Začiatočník",
        "sl": "1 Angleščina - Začetnik",
        "sr": "1 Engleski - Početni",
        "sv": "1 Engelsk - Nybörjare",
        "sw": "1 Kingereza - Ayeanza",
        "tr": "1 İngilizce - Başlangıç",
        "uk": "1  Англійська - Початківець",
        "ur": "1 انگریزی - نوآموز",
        "zh": "1 英语 - 新手"
    },
    "EnglishLMBeginner_inklVideos_v1": {
        "default": "English4Beginners incl. videos"
    },
    "EnglishLMElementary_Footballettheend_v1": {
        "ar": "2 الإنجليزية - اساسي",
        "bg": "2 Английски - Елементарно",
        "br": "2 Inglês - Básico",
        "ca": "2  Anglès - Elemental",
        "cz": "2 Anglicky - Pokročilý začátečník",
        "de": "2 Englisch - Grundlagen",
        "default": "2 English - Elementary",
        "el": "2 Αγγλικά - Στοιχειώδης",
        "er": "2 Inglés - Elemental",
        "es": "2 Inglés - Elemental",
        "ex": "2 Inglés - Elemental",
        "fa": "2 انگلیسی - مقدماتی",
        "fr": "2 Anglais - Élémentaire",
        "hi": "2 अंग्रेजी - प्राथमिक",
        "hr": "2 Engleski - Osnovni",
        "hu": "2 Angol - Alapszint",
        "hy": "2 Անգլերեն - Տարրական",
        "it": "2 Inglese - Elementare",
        "ja": "2 英語 - 初級者",
        "ko": "2 영어 - 초급",
        "lt": "2 Anglų k. - Pradinis",
        "nl": "2 Engels - Elementair",
        "no": "2 Engelsk - Lett",
        "pl": "2 Angielski - Podstawowy",
        "pt": "2 Inglês - Elementar",
        "ro": "2 Engleză - Elementar",
        "ru": "2 Английский - Элементарный уровень",
        "rw": "2 Icyongereza - Uwo ku rugero rwo hasi",
        "sk": "2 Angličtina - Elementárna úroveň",
        "sl": "2 Angleščina - Osnovno znanje",
        "sr": "2 Engleski - Osnovni",
        "sv": "2 Engelsk - Elementärt",
        "sw": "2 Kingereza - Ya msingi",
        "tr": "2 İngilizce - Temel",
        "uk": "2  Англійська - Елементарно",
        "ur": "2 انگریزی - ابتدائی",
        "zh": "2 英语 - 初级"
    },
    "EnglishLmPIM_Footballattheend_v1": {
        "ar": "3 الإنجليزية -  قبل االمتوسط",
        "bg": "3 Английски - Предварителен междинен",
        "br": "3 Inglês - Pré-intermediário",
        "ca": "3 Anglès - Pre-intermedi",
        "cz": "3 Anglicky - Mírně pokročilý",
        "de": "3 Englisch - Erweiterte Grundlagen",
        "default": "3 English - Pre-intermediate",
        "el": "3 Αγγλικά - Προ-ενδιάμεσο",
        "er": "3 Inglés - Preintermedio",
        "es": "3 Inglés - Preintermedio",
        "ex": "3 Inglés - Pre-intermedio",
        "fa": "3 انگلیسی - متوسط رو به پایین",
        "fr": "3 Anglais - Pré-intermédiaire",
        "hi": "3 अंग्रेज़ी - पूर्व मध्यवर्ती",
        "hr": "3 Engleski - Pred-srednji",
        "hu": "3 Angol - Középhaladó",
        "hy": "3 Անգլերեն - Նախամիջին",
        "it": "3 Inglese - Pre-intermedio",
        "ja": "3 英語 - 中級下",
        "ko": "3 영어 - 중하급",
        "lt": "3 Anglų k. - Žemesnis nei vidutinis",
        "nl": "3 Engels - Pre-intermediate",
        "no": "3 Engelsk - Pre-mellomliggende",
        "pl": "3 Angielski - Niższy średnio zaawansowany",
        "pt": "3 Inglês - Pré-intermédio",
        "ro": "3 Engleză - Pré-intermédio",
        "ru": "3 Английский - Ниже среднего",
        "rw": "3 Icyongereza - Ikibanziriza icyiciro cyo hagati",
        "sk": "3 Angličtina - Mierne pokročilý",
        "sl": "3 Angleščina - Nižji-srednji",
        "sr": "3 Engleski - Niži-srednji",
        "sv": "3 Engelsk - Pre-mellanprodukt",
        "sw": "3 Kingereza - Kabla ya ya kati",
        "tr": "3 İngilizce - Alt orta seviye",
        "uk": "3 Англійська - попередній проміжний",
        "ur": "3 انگریزی - قبل از وسط",
        "zh": "3 英语 - 预中间"
    },
    "EnglishMacMillanBEstarter": { "default": "Business English Starter" },
    "EnglishMildenbergerHigh5": {
        "de": "high5 - Englisch für Kinder",
        "default": "high5 - Englisch für Kinder"
    },
    "EnglishModelAcademy": { "default": "Model Academy" },
    "EnglishModelContentGesamtInAppPurchase": {
        "default": "English4Models, Fashion & Beauty"
    },
    "EnglishModelContentGesamtInklEvents": {
        "default": "English4Models, Fashion & Beauty"
    },
    "EnglishModelContentInklVideos": {
        "default": "English4Models, Fashion & Beauty incl. Videos (old)"
    },
    "EnglishModelContentInklVideosV2": {
        "default": "English4Models, Fashion & Beauty incl. Videos (old)"
    },
    "EnglishModelContentInklVideosV3": {
        "default": "English4Models, Fashion & Beauty incl. Videos"
    },
    "EnglishModelContentInklVideosV3IAP": {
        "default": "English4Models, Fashion & Beauty incl. Videos"
    },
    "EnglishModelingContent": { "default": "English4Models" },
    "EnglishModelingEvents": { "default": "English4Models - Events" },
    "EnglishModelmanagementDemoKoreanisch": {
        "default": "English4Models - Korean Version"
    },
    "EnglishMusic": { "default": "English4MusicFans" },
    "EnglishNeveonCoursebook": { "default": "NEVEON Coursebook" },
    "EnglishOUPEnglishFilePre-Intermediate": {
        "default": "English File Pre-Intermediate"
    },
    "EnglishOxfordBusinessEnglishAdvanced": {
        "default": "Business Result 6 - Advanced"
    },
    "EnglishOxfordBusinessEnglishAdvanced_IAP": {
        "default": "Business Result 6 - Advanced"
    },
    "EnglishOxfordBusinessEnglishElementary": {
        "default": "Business Result 2 - Elementary"
    },
    "EnglishOxfordBusinessEnglishElementary_IAP": {
        "default": "Business Result 2 - Elementary"
    },
    "EnglishOxfordBusinessEnglishIntermediate": {
        "default": "Business Result 4 - Intermediate"
    },
    "EnglishOxfordBusinessEnglishIntermediate_IAP": {
        "default": "Business Result 4 - Intermediate"
    },
    "EnglishOxfordBusinessEnglishPreIntermediate": {
        "default": "Business Result 3 - Pre-Intermediate"
    },
    "EnglishOxfordBusinessEnglishPreIntermediate_IAP": {
        "default": "Business Result 3 - Pre-Intermediate"
    },
    "EnglishOxfordBusinessEnglishStarter": {
        "default": "Business Result 1 - Starter"
    },
    "EnglishOxfordBusinessEnglishStarter_IAP": {
        "default": "Business Result 1 - Starter"
    },
    "EnglishOxfordBusinessEnglishStarter_inclVideos": {
        "default": "Business Result - Starter incl. videos"
    },
    "EnglishOxfordBusinessEnglishUpperIntermediate": {
        "default": "Business Result 5 - Upper-Intermediate"
    },
    "EnglishOxfordBusinessEnglishUpperIntermediate_IAP": {
        "default": "Business Result 5 - Upper-Intermediate"
    },
    "EnglishPIM": { "default": "British English 3 - Pre-intermediate" },
    "EnglishPersonal": { "default": "My personal English" },
    "EnglishPimAE": { "default": "American English 3 - Pre-Intermediate" },
    "EnglishReadOnSchuenemann": {
        "de": "Die Sprachzeitung",
        "default": "Die Sprachzeitung"
    },
    "EnglishReinigungEN": {
        "ar": "خدمات تنظيف المباني",
        "bg": "Почистване на сгради",
        "cz": "Úklid budov ",
        "de": "Reinigung Gebäude",
        "default": "Facility cleaning",
        "es": "Limpieza industrial",
        "fa": "نظافت ساختمان",
        "fr": "Nettoyage des bâtiments",
        "hr": "Čišćenje zgrada",
        "hu": "Épület takarítás",
        "nl": "Industriële Reiniging",
        "pl": "Sprzątanie budynku",
        "ro": "Curățenia în clădire",
        "ru": "Уборка зданий",
        "sk": "Čistenie budov",
        "sl": "Čiščenje zgradb",
        "sr": "Čišćenje zgrada",
        "tr": " Bina temizliği"
    },
    "EnglishSantillanaPublisherDemo": { "default": "Santillana Demo" },
    "EnglishTourismB1": {
        "ar": "الإنجليزية - متوسّط السياحة",
        "de": "Englisch - Tourismus Mittelstufe",
        "default": "English - Tourism Intermediate",
        "es": "Inglés - Tourismo Intermediario",
        "fr": "Anglais - Tourisme Intermédiaire",
        "it": "Inglese - Turismo Intermediario",
        "pl": "Angielski - Turystyka Pośredni",
        "pt": "Inglês - Turismo Intermediário",
        "ru": "Английский - туризм промежуточный уровень",
        "tr": "İngilizce - Turizm Orta düzey"
    },
    "EnglishTourismB2": {
        "ar": "الإنجليزية -  فوق المتوسط",
        "de": "Englisch - Tourismus Obere Mittelstufe",
        "default": "English - Tourism Upper Intermediate",
        "es": "Inglés - Tourismo Intermedio alto",
        "fr": "Anglais - Tourisme Intermédiaire supérieur",
        "it": "Inglese - Turismo Intermediario superiore",
        "pl": "Angielski - Turystyka Górny Pośredni",
        "pt": "Inglês - Turismo Intermediário superior",
        "ru": "Английский - туризм Верхний Промежуточный уровень",
        "tr": "İngilizce - Turizm Üst orta düzey"
    },
    "EnglishUARS_Gruwo": {
        "default": "Basic Vocabulary",
        "ru": "Базовая лексика - Немецкий",
        "uk": "Словник базовий - Німецько"
    },
    "EnglishUA_GruWo": {
        "default": "English - Basic Vocabulary",
        "uk": "Українсько - Словник базовий"
    },
    "EnglishUA_High5": { "default": "Hi5 for Kids" },
    "EnglishUA_LMBeginnerA1": {
        "default": "English 1 - Beginner",
        "uk": "Англійська 1  - Початківець"
    },
    "EnglishUA_LMBeginnerA1BVB": {
        "default": "1 English - Beginner",
        "uk": "1  Англійська - Початківець"
    },
    "EnglishUA_LMElementaryA2_1": {
        "default": "English 2 - Elementary",
        "uk": "Англійська 2  - Елементарно"
    },
    "EnglishUA_LMElementaryA2_1_BVB": {
        "default": "2 English - Elementary",
        "uk": "2  Англійська - Елементарно"
    },
    "EnglishUA_LMFootball": { "default": "Football", "uk": "Футбол" },
    "EnglishUA_LMIntermediateB1": {
        "default": "English 4 - Intermediate",
        "uk": "Англійська 4 - середній рівень"
    },
    "EnglishUA_LMPre-IntermediateA2_2": {
        "default": "English 3 - Pre-Intermediate",
        "uk": "Англійська 3 - попередній проміжний"
    },
    "EnglishUA_LMPre-IntermediateA2_2_BVB": {
        "default": "3 English - Pre-Intermediate",
        "uk": "3 Англійська - попередній проміжний"
    },
    "EnglishUA_LMUpperIntermediateB2": {
        "default": "English 5 - Upper Intermediate",
        "uk": "Англійська 5 - вищий середній рівень"
    },
    "EnglishUpperIntermediateB2": {
        "default": "British English 5 - Upper Intermediate"
    },
    "EnglishUpperIntrmediateB2AE": {
        "default": "American English 5 - Upper Intermediate"
    },
    "EnglishZombiesChapter1and2": {
        "br": "English vs. Zombies",
        "de": "English vs. Zombies",
        "default": "English vs. Zombies",
        "es": "English vs. Zombies",
        "ja": "English vs. Zombies",
        "tr": "English vs. Zombies"
    },
    "FrenchBeginner": {
        "ar": "1 الفرنسية - مُبتدىء",
        "bg": "Френски 1 - Начинаещ",
        "br": "Francês 1 - Iniciante",
        "ca": "Francès 1 - Principiant",
        "cz": "Francouzsky 1 - Začátečník",
        "de": "Französisch 1 - Anfänger",
        "default": "French 1 - Beginner",
        "el": "Γαλλικά 1 - Αρχάριος",
        "en": "French 1 - Beginner",
        "er": "Francés 1 - Principiante",
        "es": "Francés 1 - Principiante",
        "ex": "Francés 1 - Principiante",
        "fa": "1 فرانسوی - مبتدی",
        "hi": "फ्रेंच 1 - नौसिखिया",
        "hr": "Francuski 1 - Početnik",
        "hu": "Francia 1 - Kezdő",
        "hy": "Ֆրանսերեն 1 - Սկսնակ",
        "it": "Francese 1 - Principiante",
        "ja": "フランス語 1 - 初心者",
        "ko": "프랑스어 1 - 초심자",
        "lt": "Prancūzų k. 1 - Pradedantysis",
        "nl": "Frans 1  - Beginner",
        "no": "Fransk 1 - Begynner",
        "pl": "Francuski 1 - Początkujący",
        "pt": "Francês 1 - Principiante",
        "ro": "Franceză 1 - Începător",
        "ru": "Французский 1 - Начинающий",
        "rw": "Igifaransa 1 - Umutangizi",
        "sk": "Francúzština 1 - Začiatočník",
        "sl": "Francoščina 1 - Začetnik",
        "sr": "Francuski 1 - Početni",
        "sv": "Franska 1 - Nybörjare",
        "sw": "Kifaransa 1 - Ayeanza",
        "tr": "Fransızca 1 - Başlangıç",
        "uk": "Французька 1 - Початківець",
        "ur": "1 فرانسیسی - نوآموز",
        "zh": "法语 1 - 新手"
    },
    "FrenchFootball": {
        "ar": "الفرنسية - كرة القدم",
        "bg": "Френски - Футбол",
        "br": "Francês - Futebol",
        "cz": "Francouzsky - Fotbal",
        "de": "Französisch - Fußball",
        "default": "French - Football",
        "el": "Γαλλικά - Ποδόσφαιρο",
        "en": "French - Football",
        "er": "Francés - Fútbol",
        "es": "Francés - Fútbol",
        "ex": "Francés - Futbol",
        "fa": "فرانسوی - فوتبال",
        "hi": "फ्रेंच - फुटबॉल",
        "hr": "Francuski - Nogomet",
        "hu": "Francia - Futball",
        "hy": "Ֆրանսերեն - Ֆուտբոլ",
        "it": "Francese - Calcio",
        "ja": "フランス語 - サッカー",
        "ko": "프랑스어 - 축구",
        "lt": "Prancūzų k. - Futbolas",
        "no": "Fransk - Fotball",
        "pl": "Francuski - Piłka nożna",
        "pt": "Francês - Futebol",
        "ro": "Franceză - Fotbal",
        "ru": "Французский - Футбол",
        "rw": "Igifaransa - Umupira w'amaguru",
        "sk": "Francúzština - Futbal",
        "sl": "Francoščina - Nogomet",
        "sr": "Francuski - fudbal",
        "sv": "Franska - Fotboll",
        "sw": "Kifaransa - Kandanda",
        "tr": "Fransızca - Futbol",
        "ur": "فرانسیسی - فٹ بال",
        "zh": "法语 - 足球"
    },
    "FrenchIntermediate": {
        "ar": "2 الفرنسية - اساسي",
        "bg": "Френски 2 - Елементарно",
        "br": "Francês 2 - Básico",
        "ca": "Francès 2 - Elemental",
        "cz": "Francouzsky 2 - Pokročilý začátečník",
        "de": "Französisch 2 - Grundlagen",
        "default": "French 2 - Elementary",
        "el": "Γαλλικά 2 - Στοιχειώδης",
        "en": "French 2 - Elementary",
        "er": "Francés 2 - Elemental",
        "es": "Francés 2 - Elemental",
        "ex": "Francés 2 - Elemental",
        "fa": "2 فرانسوی - مقدماتی",
        "hi": "फ्रेंच 2 - प्राथमिक",
        "hr": "Francuski 2 - Osnovni",
        "hu": "Francia 2 - Alapszint",
        "hy": "Ֆրանսերեն 2 - Տարրական",
        "it": "Francese 2 - Elementare",
        "ja": "フランス語 2 - 初級者",
        "ko": "프랑스어 2 - 초급",
        "lt": "Prancūzų k. 2 - Pradinis",
        "nl": "Frans 2  - Elementair",
        "no": "Fransk 2 - Lett",
        "pl": "Francuski 2 - Podstawowy",
        "pt": "Francês 2 - Elementar",
        "ro": "Franceză 2 - Elementar",
        "ru": "Французский 2 - Элементарный уровень",
        "rw": "Igifaransa 2 - Uwo ku rugero rwo hasi",
        "sk": "Francúzština 2 - Elementárna úroveň",
        "sl": "Francoščina 2 - Osnovno znanje",
        "sr": "Francuski 2 - Osnovni",
        "sv": "Franska 2 - Elementärt",
        "sw": "Kifaransa 2 - Ya msingi",
        "tr": "Fransızca 2 - Temel",
        "uk": "Французька 2 - Елементарно",
        "ur": "2 فرانسیسی - ابتدائی",
        "zh": "法语 2 - 初级"
    },
    "FrenchPIM": {
        "ar": "3 الفرنسية -  قبل االمتوسط",
        "bg": "Френски 3 - Предварителен междинен",
        "br": "Francês 3 - Pré-intermediário",
        "ca": "Francès 3 - Pre-intermedi",
        "cz": "Francouzsky 3 - Mírně pokročilý",
        "de": "Französisch 3 - Erweiterte Grundlagen",
        "default": "French 3 - Pre-intermediate",
        "el": "Γαλλικά 3 - Προ-ενδιάμεσο",
        "en": "French 3 - Pre-intermediate",
        "er": "Francés 3 - Preintermedio",
        "es": "Francés 3 - Preintermedio",
        "ex": "Francés 3 - Preintermedio",
        "fa": "3 فرانسوی - متوسط رو به پایین",
        "hi": "फ्रेंच 3 - पूर्व मध्यवर्ती",
        "hr": "Francuski 3 - Pred-srednji",
        "hu": "Francia 3 - Középhaladó",
        "hy": "Ֆրանսերեն 3 - Նախամիջին",
        "it": "Francese 3 - Pre-intermedio",
        "ja": "フランス語 3 - 中級下",
        "ko": "프랑스어 3 - 중하급",
        "lt": "Prancūzų k. 3 - Žemesnis nei vidutinis",
        "nl": "Frans 3 - Pre-intermediate",
        "no": "Fransk 3 - Pre-mellomliggende",
        "pl": "Francuski 3 - Niższy średnio zaawansowany",
        "pt": "Francês 3 - Pré-intermédio",
        "ro": "Franceză 3 - Pre-intermediar",
        "ru": "Французский 3 - Ниже среднего",
        "rw": "Igifaransa 3 - Ikibanziriza icyiciro cyo hagati",
        "sk": "Francúzština 3 - Mierne pokročilý",
        "sl": "Francoščina 3 - Nižji-srednji",
        "sr": "Francuski 3 - Niži-srednji",
        "sv": "Franska 3 - Pre-mellanprodukt",
        "sw": "Kifaransa 3 - Kabla ya ya kati",
        "tr": "Fransızca 3 - Alt orta seviye",
        "uk": "Французька 3 -  попередній проміжний",
        "ur": "3 فرانسیسی - قبل از وسط",
        "zh": "法语 3 - 预中间"
    },
    "FrenchPersonal": { "default": "Mon français personnel" },
    "FrenchUA_LMBeginnerA1": {
        "default": "French 1 - Beginner",
        "uk": "Французька 1 - Початківець"
    },
    "FrenchUA_LMElementaryA2": {
        "default": "French 2 - Elementary",
        "uk": "Французька 2 - Елементарно"
    },
    "FrenchUA_LMFootball": { "default": "Football", "uk": "Футбол" },
    "FrenchUA_LMPreIntermediateA2_2": {
        "default": "French 3 - Pre-Intermediate",
        "uk": "Французька 3 -  попередній проміжний"
    },
    "ItalianBeginner": {
        "ar": "1 الإيطالية - مُبتدىء",
        "bg": "Италиански 1 - Начинаещ",
        "br": "Italiano 1 - Iniciante",
        "ca": "Italià 1 - Principiant",
        "cz": "Italsky 1 - Začátečník",
        "de": "Italienisch 1 - Anfänger",
        "default": "Italian 1 - Beginner",
        "el": "Ιταλικά 1 - Αρχάριος",
        "en": "Italian 1 - Beginner",
        "er": "Italiano 1 - Principiante",
        "es": "Italiano 1 - Principiante",
        "ex": "Italiano 1 - Principiante",
        "fa": "1 ایتالیایی - مبتدی",
        "fr": "Italien 1 - Débutant",
        "hi": "इतालवी 1 - नौसिखिया",
        "hr": "Talijanski 1 - Početnik",
        "hu": "Olasz 1 - Kezdő",
        "hy": "Իտալերեն 1 - Սկսնակ",
        "ja": "イタリア語 1 - 初心者",
        "ko": "이탈리아어 1 - 초심자",
        "lt": "Italų k. 1 - Pradedantysis",
        "nl": "Italiaans 1 - Beginner",
        "no": "Italiensk 1 - Begynner",
        "pl": "Włoski 1 - Początkujący",
        "pt": "Italiano 1 - Principiante",
        "ro": "Italiană 1 - Începător",
        "ru": "Итальянский 1 - Начинающий",
        "rw": "Igitaliyani 1 - Umutangizi",
        "sk": "Taliančina 1 - Začiatočník",
        "sl": "Italijanščina 1 - Začetnik",
        "sr": "Italijanski 1 - Početni",
        "sv": "Italienska 1 - Nybörjare",
        "sw": "Kiitalia 1 - Ayeanza",
        "tr": "İtalyanca 1 - Başlangıç",
        "uk": "Італійська 1 - Початківець",
        "ur": "1 اطالوی - نوآموز",
        "zh": "意大利语 1 - 新手"
    },
    "ItalianFootball": {
        "ar": "الإيطالية -  كرة القدم",
        "bg": "Италиански - Футбол",
        "br": "Italiano - Futebol",
        "cz": "Italsky - Fotbal",
        "de": "Italienisch - Fußball",
        "default": "Italian - Football",
        "el": "Ιταλικά - Ποδόσφαιρο",
        "en": "Italian - Football",
        "er": "Italiano - Fútbol",
        "es": "Italiano - Fútbol",
        "ex": "Italiano -  Futbol",
        "fa": "ایتالیایی - فوتبال",
        "fr": "Italien - Football",
        "hi": "इतालवी - फुटबॉल",
        "hr": "Talijanski - Nogomet",
        "hu": "Olasz - Futball",
        "hy": "Իտալերեն - Ֆուտբոլ",
        "ja": "イタリア語 - サッカー",
        "ko": "이탈리아어 - 축구",
        "lt": "Italų k. - Futbolas",
        "no": "Italiensk - Fotball",
        "pl": "Włoski - Piłka nożna",
        "pt": "Italiano - Futebol",
        "ro": "Italiană - Fotbal",
        "ru": "Итальянский - Футбол",
        "rw": "Igitaliyani - Umupira w'amaguru",
        "sk": "Taliančina - Futbal",
        "sl": "Italijanščina - Nogomet",
        "sr": "Italijanski - fudbal",
        "sv": "Italienska - Fotboll",
        "sw": "Kiitalia - Kandanda",
        "tr": "İtalyanca - Futbol",
        "ur": "اطالوی - فٹ بال",
        "zh": "意大利语 - 足球"
    },
    "ItalianIntermediate": {
        "ar": "2 الإيطالية - اساسي",
        "bg": "Италиански 2 - Елементарно",
        "br": "Italiano 2 - Básico",
        "ca": "Italià 2 - Elemental",
        "cz": "Italsky 2 - Pokročilý začátečník",
        "de": "Italienisch 2 - Grundlagen",
        "default": "Italian 2 - Elementary",
        "el": "Ιταλικά 2 - Στοιχειώδης",
        "en": "Italian 2 - Elementary",
        "er": "Italiano 2 - Elemental",
        "es": "Italiano 2 - Elemental",
        "ex": "Italiano 2 - Elemental",
        "fa": "2 ایتالیایی - مقدماتی",
        "fr": "Italien 2 - Élémentaire",
        "hi": "इतालवी 2 - प्राथमिक",
        "hr": "Talijanski 2 - Osnovni",
        "hu": "Olasz 2 - Alapszint",
        "hy": "Իտալերեն 2 - Տարրական",
        "ja": "イタリア語 2 - 初級者",
        "ko": "이탈리아어 2 - 초급",
        "lt": "Italų k. 2 - Pradinis",
        "nl": "Italiaans 2 - Elementair",
        "no": "Italiensk 2 - Lett",
        "pl": "Włoski 2 - Podstawowy",
        "pt": "Italiano 2 - Elementar",
        "ro": "Italiană 2 - Elementar",
        "ru": "Итальянский 2 - Элементарный уровень",
        "rw": "Igitaliyani 2 - Uwo ku rugero rwo hasi",
        "sk": "Taliančina 2 - Elementárna úroveň",
        "sl": "Italijanščina 2 - Osnovno znanje",
        "sr": "Italijanski 2 - Osnovni",
        "sv": "Italienska 2 - Elementärt",
        "sw": "Kiitalia 2 - Ya msingi",
        "tr": "İtalyanca 2 - Temel",
        "uk": "Італійська 2 - Елементарно",
        "ur": "2 اطالوی - ابتدائی",
        "zh": "意大利语 2 - 初级"
    },
    "ItalianPIM": {
        "ar": "3 الإيطالية -  قبل االمتوسط",
        "bg": "Италиански 3 - Предварителен междинен",
        "br": "Italiano 3 - Pré-intermediário",
        "ca": "Italià 3 - Pre-intermedi",
        "cz": "Italsky 3 - Mírně pokročilý",
        "de": "Italienisch 3 - Erweiterte Grundlagen",
        "default": "Italian 3 - Pre-intermediate",
        "el": "Ιταλικά 3 - Προ-ενδιάμεσο",
        "en": "Italian 3 - Pre-intermediate",
        "er": "Italiano 3 - Preintermedio",
        "es": "Italiano 3 - Preintermedio",
        "ex": "Italiano 3 - Pre-intermedio",
        "fa": "3 ایتالیایی - متوسط رو به پایین",
        "fr": "Italien 3 - Pré-intermédiaire",
        "hi": "इतालवी 3 - पूर्व मध्यवर्ती",
        "hr": "Talijanski 3 - Pred-srednji",
        "hu": "Olasz 3 - Középhaladó",
        "hy": "Իտալերեն 3 - Նախամիջին",
        "ja": "イタリア語 3 - 中級下",
        "ko": "이탈리아어 3 - 중하급",
        "lt": "Italų k. 3 - Žemesnis nei vidutinis",
        "nl": "Italiaans 3 - Pre-intermediate",
        "no": "Italiensk 3 - Pre-mellomliggende",
        "pl": "Włoski 3 - Niższy średnio zaawansowany",
        "pt": "Italiano 3 - Pré-intermédio",
        "ro": "Italiană 3 - Pre-intermediar",
        "ru": "Итальянский 3 - Ниже среднего",
        "rw": "Igitaliyani 3 - Ikibanziriza icyiciro cyo hagati",
        "sk": "Taliančina 3 - Mierne pokročilý",
        "sl": "Italijanščina 3 - Nižji-srednji",
        "sr": "Italijanski 3 - Niži-srednji",
        "sv": "Italienska 3 - Pre-mellanprodukt",
        "sw": "Kiitalia 3 - Kabla ya ya kati",
        "tr": "İtalyanca 3 - Alt orta seviye",
        "uk": "Італійська 3 - попередній проміжний",
        "ur": "3 اطالوی - قبل از وسط",
        "zh": "意大利语 3 - 预中间"
    },
    "ItalianPersonal": { "default": "Il mio italiano personale" },
    "ItalianUA_LMBeginnerA1": {
        "default": "Italian 1 - Beginner",
        "uk": "Італійська 1 - Початківець"
    },
    "ItalianUA_LMElementaryA2_1": {
        "default": "Italian 2 - Elementary",
        "uk": "Італійська 2 - Елементарно"
    },
    "ItalianUA_LMFootball": { "default": "Football", "uk": "Футбол" },
    "ItalianUA_LMPreIntermediateA2_2": {
        "default": "Italian 3 - Pre-Intermediate",
        "uk": "Італійська 3 - попередній проміжний"
    },
    "JapaneseEdvecJapaneseA1": {
        "ar": "1 اليابانية - مُبتدىء",
        "bg": "Японски 1 - Начинаещ",
        "br": "Japonês 1 - Iniciante",
        "ca": "Japonès 1 - Principiant",
        "cz": "Japonský 1 - Začátečník",
        "de": "Japanisch 1 - Anfänger",
        "default": "Japanese 1 - Beginner",
        "el": "Ιαπωνικά 1 - Αρχάριος",
        "en": "Japanese 1 - Beginner",
        "er": "Japonés 1 - Principiante",
        "es": "Japonés 1 - Principiante",
        "ex": "Japonés 1 - Principiante",
        "fa": "1 ژاپنی - مبتدی",
        "fr": "Japonais 1 - Débutant",
        "hi": "जापानी 1 - नौसिखिया",
        "hr": "Japanski 1 - Početnik",
        "hu": "Japán 1 - Kezdő",
        "hy": "Ճապոներեն 1 - Սկսնակ",
        "it": "Giapponese 1 - Principiante",
        "ko": "일본어 1 - 초심자",
        "lt": "Japonų 1 - Pradedantysis",
        "nl": "Japans 1 - Beginner",
        "no": "Japansk 1 - Begynner",
        "pl": "Japoński 1 - Początkujący",
        "pt": "Japonês 1 - Principiante",
        "ro": "Japonez 1 - Începător",
        "ru": "Японский язык 1 - Начинающий",
        "rw": "Ikiyapani 1 - Umutangizi",
        "sk": "Japončina 1 - Začiatočník",
        "sl": "Japonski 1 - Začetnik",
        "sr": "Japanski 1 - Početni",
        "sv": "Japanska 1 - Nybörjare",
        "sw": "Kijapani 1 - Ayeanza",
        "tr": "Japonca 1 - Başlangıç",
        "uk": "Японський 1 - Початківець",
        "ur": "1 جاپانی - نوآموز",
        "zh": "日语 1 - 新手"
    },
    "JapaneseEdvecJapaneseA2_2": {
        "ar": "3 اليابانية -  قبل المتوسط",
        "bg": "3 Японски - Предварителен междинен",
        "br": "3 Japonês - Pré-intermediário",
        "ca": "3 Japonès - Pre-intermedi",
        "cz": "3 Japonský - Mírně pokročilý",
        "de": "3 Japanisch - Erweiterte Grundlagen",
        "default": "3 Japanese - Pre-intermediate",
        "el": "3 Ιαπωνικά - Προ-ενδιάμεσο",
        "en": "3 Japanese - Pre-intermediate",
        "er": "3 Japonés - Preintermedio",
        "es": "3 Japonés - Preintermedio",
        "ex": "3 Japonés - Pre-intermedio",
        "fa": "3 ژاپنی - متوسط رو به پایین",
        "fr": "3 Japonais - Pré-intermédiaire",
        "hi": "3 जापानी - पूर्व मध्यवर्ती",
        "hr": "3 Japanski - Pred-srednji",
        "hu": "3 Japán - Középhaladó",
        "hy": "3 Ճապոներեն - Նախամիջին",
        "it": "3 Giapponese - Pre-intermedio",
        "ko": "3 일본어 - 중하급",
        "lt": "3 Japonų - Žemesnis nei vidutinis",
        "nl": "3 Japans - Pre-intermediate",
        "no": "3 Japansk - Pre-mellomliggende",
        "pl": "3 Japoński - Niższy średnio zaawansowany",
        "pt": "3 Japonês - Pré-intermédio",
        "ro": "3 Japonez - Pre-intermediar",
        "ru": "3 Японский язык - Ниже среднего",
        "rw": "3 Ikiyapani - Ikibanziriza icyiciro cyo hagati",
        "sk": "3 Japončina - Mierne pokročilý",
        "sl": "3 Japonski - Nižji-srednji",
        "sr": "3 Japanski - Niži-srednji",
        "sv": "3 Japanska - Pre-mellanprodukt",
        "sw": "3 Kijapani - Kabla ya ya kati",
        "tr": "3 Japonca - Alt orta seviye",
        "uk": "3 Японський - Попередній проміжний",
        "ur": "3 جاپانی -  قبل از وسط",
        "zh": "3 日语 - 预中间"
    },
    "JapaneseEdvecMyjtBasic": {
        "de": "MyJT Basic Japanese 100",
        "default": "MyJT Basic Japanese 100",
        "en": "MyJT Basic Japanese 100"
    },
    "JapaneseEdvecMyjtIntermediate": {
        "de": "MyJT Intermediate Japanese 1600",
        "default": "MyJT Intermediate Japanese 1600",
        "en": "MyJT Intermediate Japanese 1600"
    },
    "JapaneseEdvecMyjtIntermediateDEMO": {
        "de": "DEMO MyJT Intermediate Japanese 1600",
        "default": "DEMO MyJT Intermediate Japanese 1600",
        "en": "DEMO MyJT Intermediate Japanese 1600"
    },
    "JapaneseFootballTop100": {
        "ar": "اليابانية -  كرة القدم",
        "bg": "Японски - Футбол",
        "br": "Japonês - Futebol",
        "cz": "Japonský - Fotbal",
        "de": "Japanisch - Fußball",
        "default": "Japanese - Football",
        "el": "Ιαπωνικά - Ποδόσφαιρο",
        "en": "Japanese - Football",
        "er": "Japonés - Fútbol",
        "es": "Japonés - Fútbol",
        "ex": "Japonés - Futbol",
        "fa": "ژاپنی - فوتبال",
        "fr": "Japonais - Football",
        "hi": "जापानी - फुटबॉल",
        "hr": "Japanski - Nogomet",
        "hu": "Japán - Futball",
        "hy": "Ճապոներեն - Ֆուտբոլ",
        "it": "Giapponese - Calcio",
        "ko": "일본어 - 축구",
        "lt": "Japonų - Futbolas",
        "no": "Japansk - Fotball",
        "pl": "Japoński - piłka nożna",
        "pt": "Japonês - Futebol",
        "ro": "Japonez - Fotbal",
        "ru": "Японский язык - Футбол",
        "rw": "Ikiyapani - Umupira w'amaguru",
        "sk": "Japončina - Futbal",
        "sl": "Japonski - Nogomet",
        "sr": "Japanski - fudbal",
        "sv": "Japanska - Fotboll",
        "sw": "Kijapani - Kandanda",
        "tr": "Japonca - Futbol",
        "ur": "جاپانی - فٹ بال",
        "zh": "日语 - 足球"
    },
    "JapaneseJapanNihongoSampleJapanese": {
        "default": "Nihongo dojo",
        "en": "Nihongo dojo"
    },
    "JapaneseLMJapaneseElementaryA21": {
        "ar": "2 ژاپنی - مقدماتی",
        "bg": "Японски 2 - Елементарно",
        "br": "Japonês 2 - Básico",
        "ca": "Japonès 2 - Elemental",
        "cz": "Japonský 2 - Pokročilý začátečník",
        "de": "Japanisch 2 - Grundlagen",
        "default": "Japanese 2 - Elementary",
        "el": "Ιαπωνικά 2 - Στοιχειώδης",
        "en": "Japanese 2 - Elementary",
        "er": "Japonés 2 - Elemental",
        "es": "Japonés 2 - Elemental",
        "ex": "Japonés 2 - Elemental",
        "fa": "2 ژاپنی - مقدماتی",
        "fr": "Japonais 2 - Élémentaire",
        "hi": "जापानी 2 - प्राथमिक",
        "hr": "Japanski 2 - Osnovni",
        "hu": "Japán 2 - Alapszint",
        "hy": "Ճապոներեն 2 - Տարրական",
        "it": "Giapponese 2 - Elementare",
        "ko": "일본어 2 - 초급",
        "lt": "Japonų 2 - Pradinis",
        "nl": "Japans 2 - Elementair",
        "no": "Japansk 2 - Lett",
        "pl": "Japoński 2 - Podstawowy",
        "pt": "Japonês 2 - Elementar",
        "ro": "Japonez 2 - Elementar",
        "ru": "Японский язык 2 - Элементарный уровень",
        "rw": "Ikiyapani 2 - Uwo ku rugero rwo hasi",
        "sk": "Japončina 2 - Elementárna úroveň",
        "sl": "Japonski 2 - Osnovno znanje",
        "sr": "Japanski 2 - Osnovni",
        "sv": "Japanska 2 - Elementärt",
        "sw": "Kijapani 2 - Ya msingi",
        "tr": "Japonca 2 - Temel",
        "uk": "Японський 2 - Елементарно",
        "ur": "2 جاپانی - ابتدائی",
        "zh": "日语 2 - 初级"
    },
    "JapaneseLMPreIntermediateA22": {
        "ar": "3 اليابانية -  قبل المتوسط",
        "bg": "Японски 3 - Предварителен междинен",
        "br": "Japonês 3 - Pré-intermediário",
        "ca": "Japonès 3 - Pre-intermedi",
        "cz": "Japonský 3 - Mírně pokročilý",
        "de": "Japanisch 3 - Erweiterte Grundlagen",
        "default": "Japanese 3 - Pre-intermediate",
        "el": "Ιαπωνικά 3 - Προ-ενδιάμεσο",
        "en": "Japanese 3 - Pre-intermediate",
        "er": "Japonés 3 - Preintermedio",
        "es": "Japonés 3 - Preintermedio",
        "ex": "Japonés 3 - Pre-intermedio",
        "fa": "3 ژاپنی - متوسط رو به پایین",
        "fr": "Japonais 3 - Pré-intermédiaire",
        "hi": "जापानी 3 - पूर्व मध्यवर्ती",
        "hr": "Japanski 3 - Pred-srednji",
        "hu": "Japán 3 - Középhaladó",
        "hy": "Ճապոներեն 3 - Նախամիջին",
        "it": "Giapponese 3 - Pre-intermedio",
        "ko": "일본어 3 - 중하급",
        "lt": "Japonų 3 - Žemesnis nei vidutinis",
        "nl": "Japans 3 - Pre-intermediate",
        "no": "Japansk 3 - Pre-mellomliggende",
        "pl": "Japoński 3 - Niższy średnio zaawansowany",
        "pt": "Japonês 3 - Pré-intermédio",
        "ro": "Japonez 3 - Pre-intermediar",
        "ru": "Японский язык 3 - Ниже среднего",
        "rw": "Ikiyapani 3 - Ikibanziriza icyiciro cyo hagati",
        "sk": "Japončina 3 - Mierne pokročilý",
        "sl": "Japonski 3 - Nižji-srednji",
        "sr": "Japanski 3 - Niži-srednji",
        "sv": "Japanska 3 - Pre-mellanprodukt",
        "sw": "Kijapani 3 - Kabla ya ya kati",
        "tr": "Japonca 3 - Alt orta seviye",
        "uk": "Японський 3 - Попередній проміжний",
        "ur": "3 جاپانی -  قبل از وسط",
        "zh": "日语 3 - 预中间"
    },
    "PolishTest1": {
        "ar": "PolishTest1",
        "bg": "PolishTest1",
        "ca": "PolishTest1",
        "cz": "PolishTest1",
        "default": "PolishTest1",
        "el": "PolishTest1",
        "en": "PolishTest1",
        "es": "PolishTest1",
        "fa": "PolishTest1",
        "fr": "PolishTest1",
        "hr": "PolishTest1",
        "hu": "PolishTest1",
        "it": "PolishTest1",
        "lt": "PolishTest1",
        "nl": "PolishTest1",
        "pt": "PolishTest1",
        "ro": "PolishTest1",
        "ru": "PolishTest1",
        "sk": "PolishTest1",
        "sl": "PolishTest1",
        "sq": "PolishTest1",
        "sr": "PolishTest1",
        "tr": "PolishTest1",
        "uk": "PolishTest1",
        "zh": "PolishTest1"
    },
    "PortugueseBeginner": {
        "ar": "1 البرتغالية - مُبتدىء",
        "bg": "Португалски 1 - Начинаещ",
        "br": "Português 1 - Iniciante",
        "ca": "Portuguès 1 - Principiant",
        "cz": "Portugalsky 1 - Začátečník",
        "de": "Portugiesisch 1 - Anfänger",
        "default": "Portuguese 1 - Beginner",
        "el": "Πορτογαλικά 1 - Αρχάριος",
        "en": "Portuguese 1 - Beginner",
        "er": "Portugués 1 - Principiante",
        "es": "Portugués 1 - Principiante",
        "ex": "Portugués 1 - Principiante",
        "fa": "1 پرتغالی - مبتدی",
        "fr": "Portugais 1 - Débutant",
        "hi": "पुर्तगाली 1 - नौसिखिया",
        "hr": "Portugalski 1 - Početnik",
        "hu": "Portugál 1 -  Kezdő",
        "hy": "Պորտուգալերեն 1 - Սկսնակ",
        "it": "Portoghese 1 - Principiante",
        "ja": "ポルトガル語 1 - 初心者",
        "ko": "포르투갈어 1 - 초심자",
        "lt": "Portugalų k. 1 - Pradedantysis",
        "nl": "Portugees 1 - Beginner",
        "no": "Portugisisk 1 - Begynner",
        "pl": "Portugalski 1 - Początkujący",
        "ro": "Portugheză 1 - Începător",
        "ru": "Португальский 1 - Начинающий",
        "rw": "Igiporutugali 1 - Umutangizi",
        "sk": "Portugalčina 1 - Začiatočník",
        "sl": "Portugalščina 1 - Začetnik",
        "sr": "Portugalski 1 - Početni",
        "sv": "Portugisiska 1 - Nybörjare",
        "sw": "Kireno 1 - Ayeanza",
        "tr": "Portekizce 1 - Başlangıç",
        "uk": "Португальська 1 - Початківець",
        "ur": "1 پرتگالی - نوآموز",
        "zh": "葡萄牙语 1 - 新手"
    },
    "PortugueseFootball": {
        "ar": "البرتغالية - كرة القدم",
        "bg": "Португалски - Футбол",
        "br": "Português - Futebol",
        "cz": "Portugalsky - Fotbal",
        "de": "Portugiesisch - Fußball",
        "default": "Portuguese - Football",
        "el": "Πορτογαλικά - Ποδόσφαιρο",
        "en": "Portuguese - Football",
        "er": "Portugués - Fútbol",
        "es": "Portugués - Fútbol",
        "ex": "Portugués - Fútbol",
        "fa": "پرتغالی - فوتبال",
        "fr": "Portugais - Football",
        "hi": "पुर्तगाली - फुटबॉल",
        "hr": "Portugalski - Nogomet",
        "hu": "Portugál -  Futball",
        "hy": "Պորտուգալերեն - Ֆուտբոլ",
        "it": "Portoghese - Calcio",
        "ja": "ポルトガル語 - サッカー",
        "ko": "포르투갈어 - 축구",
        "lt": "Portugalų k. - Futbolas",
        "no": "Portugisisk - Fotball",
        "pl": "Portugalski - Piłka nożna",
        "ro": "Portugheză - Fotbal",
        "ru": "Португальский - Футбол",
        "rw": "Igiporutugali - Umupira w'amaguru",
        "sk": "Portugalčina - Futbal",
        "sl": "Portugalščina - Nogomet",
        "sr": "Portugalski - Fudbal",
        "sv": "Portugisiska - Fotboll",
        "sw": "Kireno - Kandanda",
        "tr": "Portekizce - Futbol",
        "ur": "پرتگالی - فٹ بال",
        "zh": "葡萄牙语 - 足球"
    },
    "PortugueseIntermediate": {
        "ar": "2 البرتغالية - اساسي",
        "bg": "Португалски 2 - Елементарно",
        "br": "Português 2 - Básico",
        "ca": "Portuguès 2 - Elemental",
        "cz": "Portugalsky 2 - Pokročilý začátečník",
        "de": "Portugiesisch 2 - Grundlagen",
        "default": "Portuguese 2 - Elementary",
        "el": "Πορτογαλικά 2 - Στοιχειώδης",
        "en": "Portuguese 2 - Elementary",
        "er": "Portugués 2 - Elemental",
        "es": "Portugués 2 - Elemental",
        "ex": "Portugués 2 - Elemental",
        "fa": "2 پرتغالی - مقدماتی",
        "fr": "Portugais 2 - Élémentaire",
        "hi": "पुर्तगाली 2 - प्राथमिक",
        "hr": "Portugalski 2 - Osnovni",
        "hu": "Portugál 2 - Alapszint",
        "hy": "Պորտուգալերեն 2 - Տարրական",
        "it": "Portoghese 2 - Elementare",
        "ja": "ポルトガル語 2 - 初級者",
        "ko": "포르투갈어 2 - 초급",
        "lt": "Portugalų k. 2 - Pradinis",
        "nl": "Portugees 2  - Elementair",
        "no": "Portugisisk 2 - Lett",
        "pl": "Portugalski 2 - Podstawowy",
        "ro": "Portugheză 2 - Elementar",
        "ru": "Португальский 2 - Элементарный",
        "rw": "Igiporutugali 2 - Uwo ku rugero rwo hasi",
        "sk": "Portugalčina 2 - Elementárna úroveň",
        "sl": "Portugalščina 2 - Osnovno ",
        "sr": "Portugalski 2 - Osnovni",
        "sv": "Portugisiska 2 - Elementärt",
        "sw": "Kireno 2 - Ya msingi",
        "tr": "Portekizce 2 - Temel",
        "uk": "Португальська 2 - Елементарно",
        "ur": "2 پرتگالی - ابتدائی",
        "zh": "葡萄牙语 2 - 初级"
    },
    "PortuguesePIM": {
        "ar": "3 البرتغالية -  قبل االمتوسط ",
        "bg": "Португалски 3 - Предварителен междинен",
        "br": "Português 3 - Pré-intermediário",
        "ca": "Portuguès 3 - Pre-intermedi",
        "cz": "Portugalsky 3 - Mírně pokročilý",
        "de": "Portugiesisch 3 - Erweiterte Grundlagen",
        "default": "Portuguese 3 - Pre-intermediate",
        "el": "Πορτογαλικά 3 - Προ-ενδιάμεσο",
        "en": "Portuguese 3 - Pre-intermediate",
        "er": "Portugués 3 - Preintermedio",
        "es": "Portugués 3 - Preintermedio",
        "ex": "Portugués 3 - Preintermedio",
        "fa": "3 پرتغالی - متوسط رو به پایین",
        "fr": "Portugais 3 - Pré-intermédiaire",
        "hi": "पुर्तगाली 3 - पूर्व मध्यवर्ती",
        "hr": "Portugalski 3 - Pred-srednji",
        "hu": "Portugál 3 -  Középhaladó",
        "hy": "Պորտուգալերեն 3 - Նախամիջին",
        "it": "Portoghese 3 - Pre-intermedio",
        "ja": "ポルトガル語 3 - 中級下",
        "ko": "포르투갈어 3 - 중하급",
        "lt": "Portugalų k. 3 - Žemesnis nei vidutinis",
        "nl": "Portugees 3 - Pre-intermediate",
        "no": "Portugisisk 3 - Pre-mellomliggende",
        "pl": "Portugalski 3 - Niższy średnio zaawansowany",
        "ro": "Portugheză 3 - Pre-intermediar",
        "ru": "Португальский 3 - Ниже среднего",
        "rw": "Igiporutugali 3 - Ikibanziriza icyiciro cyo hagati",
        "sk": "Portugalčina 3 - Mierne pokročilý",
        "sl": "Portugalščina 3 - Nižji-srednji",
        "sr": "Portugalski 3 - Niži-srednji",
        "sv": "Portugisiska 3 - Pre-mellanprodukt",
        "sw": "Kireno 3 - Kabla ya ya kati",
        "tr": "Portekizce 3 - Alt orta seviye",
        "uk": "Португальська 3 - попередній проміжний",
        "ur": "3 پرتگالی - قبل از وسط",
        "zh": "葡萄牙语 3 - 预中间"
    },
    "PortuguesePersonal": { "default": "Meu português pessoal" },
    "PortugueseUA_LMBeginnerA1": {
        "default": "Portuguese 1 - Beginner",
        "uk": "Португальська 1 - Початківець"
    },
    "PortugueseUA_LMElementaryA2_1": {
        "default": "Portuguese 2 - Elementary",
        "uk": "Португальська 2 - Елементарно"
    },
    "PortugueseUA_LMFootball": { "default": "Football", "uk": "Футбол" },
    "PortugueseUA_LMPreIntermediateA2_2": {
        "default": "Portuguese 3 - Pre-Intermediate",
        "uk": "Португальська 3 - попередній проміжний"
    },
    "SpanishBeginner": {
        "ar": "1 الإسبانية - مُبتدىء",
        "bg": "Испански 1 - Начинаещ",
        "br": "Espanhol 1 - Iniciante",
        "ca": "Espanyol 1 - Principiant",
        "cz": "Španělsky 1 - Začátečník",
        "de": "Spanisch 1 - Anfänger",
        "default": "Spanish 1 - Beginner",
        "el": "Ισπανικά 1 - Αρχάριος",
        "en": "Spanish 1 - Beginner",
        "er": "Español 1 - Principiante",
        "ex": "Español 1 - Principiante",
        "fa": "1 اسپانیایی - مبتدی",
        "fr": "Espagnol 1 - Débutant",
        "hi": "स्पैनिश 1 - नौसिखिया",
        "hr": "Španjolski 1 - Početnik",
        "hu": "Spanyol 1 - Kezdő",
        "hy": "Իսպաներեն 1 - Սկսնակ",
        "it": "Spagnolo 1 - Principiante",
        "ja": "スペイン語 1 - 初心者",
        "ko": "스페인어 1 - 초심자",
        "lt": "Ispanų k. 1 - Pradedantysis",
        "nl": "Spaans 1 - Beginner",
        "no": "Spansk 1 - Begynner",
        "pl": "Hiszpański 1 - Początkujący",
        "pt": "Espanhol 1 - Principiante",
        "ro": "Spaniolă 1 - Începător",
        "ru": "Испанский 1 - Начинающий",
        "rw": "Icyesipanyoro 1 - Umutangizi",
        "sk": "Španielčina 1 - Začiatočník",
        "sl": "Španščina 1 - Začetnik",
        "sr": "Španski 1 - početni",
        "sv": " Spansk 1 - Nybörjare",
        "sw": "Kihispania 1 - Ayeanza",
        "tr": "İspanyolca 1 - Başlangıç",
        "uk": "Iспанська 1 - Початківець",
        "ur": "1 ہسپانوی - نوآموز",
        "zh": "西班牙语 1 - 新手"
    },
    "SpanishFootball": {
        "ar": "الإسبانية -  كرة القدم",
        "bg": "Испански - Футбол",
        "br": "Espanhol - Futebol",
        "cz": "Španělsky - Fotbal",
        "de": "Spanisch - Fußball",
        "default": "Spanish - Football",
        "el": "Ισπανικά - Ποδόσφαιρο",
        "en": "Spanish -Football",
        "er": "Español - Fútbol",
        "ex": "Español -Fútbol",
        "fa": "اسپانیایی - فوتبال",
        "fr": "Espagnol - Football",
        "hi": "स्पैनिश - फुटबॉल",
        "hr": "Španjolski - Nogomet",
        "hu": "Spanyol - Futball",
        "hy": "Իսպաներեն - Ֆուտբոլ",
        "it": "Spagnolo - Calcio",
        "ja": "スペイン語 - サッカー",
        "ko": "스페인어 - 축구",
        "lt": "Ispanų k. - Futbolas",
        "no": "Spansk - Fotball",
        "pl": "Hiszpański - Piłka nożna",
        "pt": "Espanhol - Futebol",
        "ro": "Spaniolă - Fotbal",
        "ru": "Испанский - Футбол",
        "rw": "Icyesipanyoro - Umupira w'amaguru",
        "sk": "Španielčina - Futbal",
        "sl": "Španščina - Nogomet",
        "sr": "Španski - fudbal",
        "sw": "Kihispania - Kandanda",
        "tr": "İspanyolca - Futbol",
        "ur": "ہسپانوی - فٹ بال",
        "zh": "西班牙语 - 足球"
    },
    "SpanishIntermediate": {
        "ar": "2 الإسبانية - اساسي",
        "bg": "Испански 2 - Елементарно",
        "br": "Espanhol 2 - Básico",
        "ca": "Espanyol 2 - Elemental",
        "cz": "Španělsky 2 - Pokročilý začátečník",
        "de": "Spanisch 2 - Grundlagen",
        "default": "Spanish 2 - Elementary",
        "el": "Ισπανικά 2 - Στοιχειώδης",
        "en": "Spanish 2 - Elementary",
        "er": "Español 2 - Elemental",
        "ex": "Español 2 -Elemental",
        "fa": "2 اسپانیایی - مقدماتی",
        "fr": "Espagnol 2 - Élémentaire",
        "hi": "स्पैनिश 2 - प्राथमिक",
        "hr": "Španjolski 2 - Osnovni",
        "hu": "Spanyol 2 - Alapszint",
        "hy": "Իսպաներեն 2 - Տարրական",
        "it": "Spagnolo 2 - Elementare",
        "ja": "スペイン語 2 - 初級者",
        "ko": "스페인어 2 - 초급",
        "lt": "Ispanų k. 2 - Pradinis",
        "nl": "Spaans 2 - Elementair",
        "no": "Spansk 2 - Lett",
        "pl": "Hiszpański 2 - Podstawowy",
        "pt": "Espanhol 2 - Elementar",
        "ro": "Spaniolă 2 - Elementar",
        "ru": "Испанский 2 - Элементарный ",
        "rw": "Icyesipanyoro 2 - Uwo ku rugero rwo hasi",
        "sk": "Španielčina 2 - Elementárna úroveň",
        "sl": "Španščina 2 - Osnovno ",
        "sr": "Španski 2 - Osnovni",
        "sv": "Spansk 2 - Elementärt",
        "sw": "Kihispania 2 - Ya msingi",
        "tr": "İspanyolca 2 - Temel",
        "uk": "Iспанська 2 - Елементарно",
        "ur": "2 ہسپانوی - ابتدائی",
        "zh": "西班牙语 2 - 初级"
    },
    "SpanishPIM": {
        "ar": "3 الإسبانية -  قبل االمتوسط ",
        "bg": "Испански 3 - Предварителен междинен",
        "br": "Espanhol 3 - Pré-intermediário",
        "ca": "Espanyol 3 - Pre-intermedi",
        "cz": "Španělsky 3 - Mírně pokročilý",
        "de": "Spanisch 3 - Erweiterte Grundlagen",
        "default": "Spanish 3 - Pre-intermediate",
        "el": "Ισπανικά 3 - Προ-ενδιάμεσο",
        "en": "Spanish 3 - Pre-intermediate",
        "er": "Español 3 - Preintermedio",
        "ex": "Español 3 - Preintermedio",
        "fa": "3 اسپانیایی - متوسط ",
        "fr": "Espagnol 3 - Pré-intermédiaire",
        "hi": "स्पैनिश 3 - पूर्व मध्यवर्ती",
        "hr": "Španjolski 3 - Pred-srednji",
        "hu": "Spanyol 3 - Középhaladó",
        "hy": "Իսպաներեն 3 - Նախամիջին",
        "it": "Spagnolo 3 - Pre-intermedio",
        "ja": "スペイン語 3 - 中級下",
        "ko": "스페인어 3 - 중하급",
        "lt": "Ispanų k. 3 - Žemesnis nei vidutinis",
        "nl": "Spaans 3 - Pre-intermediate",
        "no": "Spansk 3 - Pre-mellomliggende",
        "pl": "Hiszpański 3 - Niższy średnio zaawansowany ",
        "pt": "Espanhol 3 - Pré-intermédio",
        "ro": "Spaniolă 3 - Pre-intermediar ",
        "ru": "Испанский 3 - Ниже среднего",
        "rw": "Icyesipanyoro 3 - Ikibanziriza icyiciro cyo hagati",
        "sk": "Španielčina 3 - Mierne pokročilý",
        "sl": "Španščina 3 - Nižji-srednji",
        "sr": "Španski 3 - Niži-srednji",
        "sv": "Spansk 3 - Pre-mellanprodukt",
        "sw": "Kihispania 3 - Kabla ya ya kati",
        "tr": "İspanyolca 3 - Alt orta seviye",
        "uk": "Iспанська 3 - попередній проміжний",
        "ur": "3 ہسپانوی - قبل از وسط",
        "zh": "西班牙语 3 - 预中间"
    },
    "SpanishPersonal": { "default": "Mi español personal" },
    "SpanishUA_LMBeginnerA1": {
        "default": "Spanish 1 - Beginner",
        "uk": "Iспанська 1 - Початківець"
    },
    "SpanishUA_LMElementaryA2_1": {
        "default": "Spanish 2 - Elementary",
        "uk": "Iспанська 2 - Елементарно"
    },
    "SpanishUA_LMFootball": { "default": "Football", "uk": "Футбол" },
    "SpanishUA_LMPreIntermediateA2_2": {
        "default": "Spanish 3 - Pre-Intermediate",
        "uk": "Iспанська 3 - попередній проміжний"
    }
}

export default CourseNameTranslationsMap;
