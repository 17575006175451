import React from 'react';
import './App.css';
import PromiseComponent from './PromiseComponent'
import i18next from 'i18next';
import 'react-activity/dist/react-activity.css';
import branch from 'branch-sdk';
import Constants from "./Constants";
import Loading from "./components/Loading";
import Registration from "./components/Registration";
import Login from "./components/Login";
import CodeRedemption from "./components/CodeRedemption";
import ShowCourse from "./components/ShowCourse";
import FirebaseDatabaseService from "./services/FirebaseDatabaseService";
import BranchLink from "./components/BranchLink";
import UserLanguageModel from "./models/UserLanguageModel";
import LanguageTestInfo from './components/LanguageTestInfo';
import LanguageTest from './components/LanguageTest';
import UserModel from './models/UserModel';
import addSubtractDate from 'add-subtract-date';
import DataHelper from './helpers/DataHelper';

const STAGES = {
    LOADING: 1,
    START: 2,
    PROFILE: 3,
    SHOW_COURSE: 4,
    BRANCH_LINK: 5,
    ERROR: 6,
    LOGIN: 7,
    CODE: 8,
    LANGUAGE_TEST_INFO: 9,
    LANGUAGE_TEST: 10
};

class App extends PromiseComponent {

    state = {
        stage: STAGES.LOADING,
        userName: '',
        mail: '',
        password: '',
        encryptedPassword: '',
        branchLink: '',
        imgSource: Constants.LOGO,
        company: {},
        error: '',
        locales: {},
        uid: '',
        codeString: '',
        courseLanguage: '',
        courseTitle: '',
        sponsorId: -1,
        courses: {},
        companyCode: '',
        codeType: '',
        communities: {},
        userLoggedIn: false,
        user: {},
        languageTest: '',
        sponsorLanguageTests: [],
        validForMonths: null
    };

    firebaseDatabaseService = new FirebaseDatabaseService();

    componentDidMount = async () => {
        let localesTask = this.firebaseDatabaseService.locales();
        let locales = await localesTask;
        const courses = await this.firebaseDatabaseService.getCourses();
        const communities = await this.firebaseDatabaseService.communities();
        this.getUrlParams();
        branch.init(Constants.BRANCH_KEY);
        await this.promisedSetState({locales: locales, courses: courses, communities: communities, stage: STAGES.CODE});
    };

    getUrlParams = () => {
        let urlParams = new URLSearchParams(window.location.search);
        let codeString = urlParams.get('code') ? decodeURIComponent(urlParams.get('code')) : '';
        this.setState({codeString: codeString});
    };

    markCodeAsUsed = async () => {
      if (this.state.codeType === Constants.CODE_TYPE.COMPANY_CODE) {
          await this.firebaseDatabaseService.writeRegistration(this.state.uid, this.state.sponsorId, this.state.codeString, false, i18next.language.substr(0, 2));
          await this.firebaseDatabaseService.markCompanyCodeAsUsed(this.state.sponsorId - 100000, this.state.codeString, this.state.uid);
      }
    };

    limitLocales = async (courses: Array<{Language: string, Title: string}>) => {
        let locales = {};
        let fallbackLocale = '';
        for (let i = 0; i < courses.length; i++) {
            if (this.state.courses[courses[i].Language + courses[i].Title]) {
                const availables = this.state.courses[courses[i].Language + courses[i].Title].available;
                for (let k = 0; k < availables.length; k++) {
                    if (this.state.locales.hasOwnProperty(availables[k]) && !locales.hasOwnProperty(availables[k])) {
                        if (!fallbackLocale) {
                            fallbackLocale = availables[k];
                        }
                        locales[availables[k]] = this.state.locales[availables[k]];
                    }
                }
            }
        }
        if (courses.length === 0) {
            locales = this.state.locales;
        }
        if (!locales.hasOwnProperty(i18next.language.substr(0, 2))) {
            if (locales.hasOwnProperty('en')) {
                await i18next.changeLanguage('en')
            } else if (locales.hasOwnProperty('de')) {
                await i18next.changeLanguage('de')
            } else if (fallbackLocale) {
                await i18next.changeLanguage(fallbackLocale);
            }
        }
        this.setState({locales: locales});
    };

    goOnWithRegistration = async () => {
        await this.promisedSetState({stage: STAGES.PROFILE})
    };

    goOnWithLogin = async () => {
        await this.promisedSetState({stage: STAGES.LOGIN})
    };

    goOnWithCode = async (name: string, mail: string, encryptedPassword: string, userId?: string) => {
        await this.promisedSetState({mail: mail, encryptedPassword: encryptedPassword, userLoggedIn: !!userId, uid: userId || "", userName: name });
        // community code -> check whether language test is available (if not: add max five courses)
        if (this.state.codeType === Constants.CODE_TYPE.COMPANY_CODE) {
            const sponsorLanguageTests: Array<string> = this.state.company.LanguageTests;
            if (sponsorLanguageTests && sponsorLanguageTests.length > 0) {
                // show language test info page
                const languageTests = [];
                const languageTestsFirebase = await this.firebaseDatabaseService.getLanguageTests();
                for (let i = 0; i < sponsorLanguageTests.length; i++) {
                    if (languageTestsFirebase.hasOwnProperty(sponsorLanguageTests[i])) {
                        const availables = languageTestsFirebase[sponsorLanguageTests[i]].available;
                        if (availables.includes(i18next.language.substr(0, 2))) {
                            languageTests.push(sponsorLanguageTests[i]);
                        }
                    }
                }
                if (languageTests.length > 0) {
                    this.setState({ stage: STAGES.LANGUAGE_TEST_INFO, sponsorLanguageTests: languageTests });
                } else {
                    await this.goOnToBranchLink();
                }

            } else {
                await this.goOnToBranchLink();
            }
        } else {
            await this.goOnToBranchLink(undefined, undefined, userId);
        }
    };

    addMaxFiveCoursesToUserLanguages = async () => {
        const courses: Array<{Language: string, Title: string}> = this.state.company.CoursesV2;

        if(courses && this.state.locales[i18next.language.substr(0,2)] && this.state.locales[i18next.language.substr(0, 2)].labelEn) {
            let coursesPushed = 0;
            for (let i = 0; i < courses.length; i++) {
                if (courses[i].Language !== this.state.locales[i18next.language.substr(0,2)].labelEn &&
                    !(courses[i].Language === 'Deutsch' && this.state.locales[i18next.language.substr(0,2)].labelEn === 'German')) {
                    await this.addCourseToUserLanguages(courses[i].Language, courses[i].Title);
                    coursesPushed++;
                }
                if (coursesPushed === 5) {
                    break;
                }
            }
        }
    };

    startLanguageTest = async (languageTest: string) => {
        const tempUser = UserModel.createDummyUser();
        tempUser.setName(this.state.userName);
        const avatar = await this.firebaseDatabaseService.getAvatar(this.state.company.SponsorID);
        if (avatar) {
            tempUser.setCharacter(avatar);
        } else {
            tempUser.setCharacter('lea1f.png');
        }
        this.setState({ user: tempUser, languageTest: languageTest, stage: STAGES.LANGUAGE_TEST });
    }

    goOnWithoutLanguageTest = async () => {
        await this.goOnToBranchLink();
    }

    goOnWithCourse = async (course: Object) => {
        let locales = {};
        const courseLocales = course.available;
        for (let i = 0; i < courseLocales.length; i++) {
            if (this.state.locales.hasOwnProperty(courseLocales[i])) {
                locales[courseLocales[i]] = this.state.locales[courseLocales[i]];
            }
        }
        if (!locales.hasOwnProperty(i18next.language.substr(0, 2))) {
            if (locales.hasOwnProperty('en')) {
                await i18next.changeLanguage('en')
            } else if (locales.hasOwnProperty('de')) {
                await i18next.changeLanguage('de')
            } else {
                await i18next.changeLanguage(courseLocales[0])
            }
        }
        this.setState({stage: STAGES.PROFILE, locales: locales});
    };

    goOnWithValidCode = async (codeString: string, courseLanguage: string, courseTitle: string, sponsorId: number, codeType: string, validForMonths: ?number) => {
        const stage = (codeType === Constants.CODE_TYPE.COURSE_CODE) ? STAGES.SHOW_COURSE : STAGES.PROFILE;
        if (codeType === Constants.CODE_TYPE.COMPANY_CODE) {
            await this.limitLocales(this.state.communities[sponsorId].CoursesV2);
        }
        await this.promisedSetState({
            codeString: codeString,
            courseLanguage: courseLanguage,
            courseTitle: courseTitle,
            sponsorId: sponsorId,
            codeType: codeType,
            company: this.state.communities[sponsorId],
            validForMonths: validForMonths,
            stage: stage });
        await this.loadSponsorImg();
    };

    goOnAfterTest = async (language: string, recommendedCourse: string) => {
        await this.goOnToBranchLink(language, recommendedCourse);
    };

    goOnToBranchLink = async (language?: string, recommendedCourse?: string, userId?: string, attempt = 0) => {
        return new Promise((resolve) => {
            branch.link({
                channel: 'LearnMatch CodeRedemption WebClient',
                feature: 'CodeRedemption',
                campaign: 'CodeRedemption',
                data: {
                    language: i18next.language.substr(0, 2),
                    mail: this.state.mail,
                    p: this.state.encryptedPassword,
                    companyId: this.state.sponsorId,
                    voucher: this.state.codeString,
                    directLogin: true,
                    uid: this.state.uid,
                    validated: 0
                },
            }, async (error, link) => {
                if (error) {
                    if(attempt < 5) {
                        await this.goOnToBranchLink(language, recommendedCourse, userId, attempt + 1);
                    } else {
                        if (this.state.codeType !== Constants.CODE_TYPE.COURSE_CODE) {
                            await this.registerUser();
                            await this.markCodeAsUsed();
                            if (language && recommendedCourse) {
                                await this.addCourseToUserLanguages(language, recommendedCourse);
                            } else {
                                await this.addMaxFiveCoursesToUserLanguages();
                            }
                        } else {
                            if (!userId) {
                                await this.registerUser();
                            }
                            await this.firebaseDatabaseService.markCodeAsUsed(this.state.codeString, this.state.uid);
                            await this.addCourseToUserLanguages(this.state.courseLanguage, this.state.courseTitle);
                        }
                        let fallbackLink = Constants.FALLBACKLINK;
                        await this.promisedSetState({branchLink: fallbackLink, stage: STAGES.BRANCH_LINK});
                        resolve();
                    }
                } else {
                    if (this.state.codeType !== Constants.CODE_TYPE.COURSE_CODE) {
                        await this.registerUser();
                        await this.markCodeAsUsed();
                        if (language && recommendedCourse) {
                            await this.addCourseToUserLanguages(language, recommendedCourse);
                        } else {
                            await this.addMaxFiveCoursesToUserLanguages();
                        }
                    } else {
                        if (!userId) {
                            await this.registerUser();
                        }
                        await this.firebaseDatabaseService.markCodeAsUsed(this.state.codeString, this.state.uid);
                        await this.addCourseToUserLanguages(this.state.courseLanguage, this.state.courseTitle);
                    }
                    await this.promisedSetState({branchLink: link, stage: STAGES.BRANCH_LINK});
                    if (this.state.codeType === Constants.CODE_TYPE.COMPANY_CODE) {
                        await this.firebaseDatabaseService.addBranchLink(this.state.uid, this.state.branchLink);
                    }
                    resolve();
                }
            });
        })
    };

    loadSponsorImg = async () => {
        let imgSource = '';
        if (this.state.sponsorId !== -1) {
            let path = this.state.communities[this.state.sponsorId].ImgPath;
            imgSource = await this.firebaseDatabaseService.getDownloadUrl(path);
        }
        await this.promisedSetState({imgSource: imgSource})
    };

    addCourseToUserLanguages = async (courseLanguage: string, courseTitle: string) => {
        let userLanguages = await this.firebaseDatabaseService.getUserLanguages(this.state.uid);
        if (userLanguages) {
            userLanguages = UserLanguageModel.createMultipleFromObject(userLanguages);
        } else {
            userLanguages = [];
        }
        let validUntil = '';
        if (this.state.validForMonths) {
            let date = new Date();
            addSubtractDate.add(date, this.state.validForMonths, "months");
            validUntil = date.getTime();
        }
        let userLanguage = UserLanguageModel.createFromParams(userLanguages.length, courseLanguage, courseTitle, 0, 0, validUntil);
        let isNewCourse = true;
        for (let i = 0; i < userLanguages.length; i++) {
            if (userLanguages[i].getLanguage() === courseLanguage && userLanguages[i].getTitle() === courseTitle) {
                isNewCourse = false;
                break;
            }
        }
        if (isNewCourse || validUntil) {
            userLanguages.push(userLanguage);
            await this.firebaseDatabaseService.deleteDeviceId(this.state.uid);
            await this.firebaseDatabaseService.setUpdateBusinessUserLanguages(this.state.uid);
        }
        await this.firebaseDatabaseService.saveUserLanguages(this.state.uid, userLanguages);
    };

    registerUser = async () => {
        //create user with dummy data
        let user: UserModel = UserModel.createDummyUser();
        user.setName(this.state.userName);
        user.setGender('w');

        user.setDeviceCountry(i18next.language.toUpperCase());

        const firebaseDatabaseService = new FirebaseDatabaseService();
        const avatar = await firebaseDatabaseService.getAvatar(this.state.company.SponsorID);
        if (avatar) {
            user.setCharacter(avatar);
        } else {
            user.setCharacter('lea1f.png');
        }
        const pwd = DataHelper.decryptPassword(this.state.encryptedPassword);
        const userCredentials: Object = await firebaseDatabaseService.registerWithMailAuth(this.state.mail, pwd);
        user.setFirebaseId(userCredentials.uid);
        user.setMail(this.state.mail);
        user.setPassword(pwd);
        user.setProvider('Mail');
        await firebaseDatabaseService.saveUserInitial(user, this.state.company);
        await this.promisedSetState({ uid: userCredentials.uid, user: user });
    }

    renderContent() {
        switch (this.state.stage) {
            case STAGES.LOADING:
                return (
                    <Loading/>
                );
            case STAGES.PROFILE:
                return <Registration
                    mail={this.state.mail}
                    company={this.state.company}
                    locales={this.state.locales}
                    goOnWithCode={this.goOnWithCode}
                    codeType={this.state.codeType}
                    goToLogin={this.goOnWithLogin}/>;
            case STAGES.CODE:
                return (
                    <CodeRedemption goOnWithValidCode={this.goOnWithValidCode} codeString={this.state.codeString}> </CodeRedemption>
                );
            case STAGES.BRANCH_LINK:
                return (
                    <BranchLink branchLink={this.state.branchLink} userLoggedIn={this.state.userLoggedIn}> </BranchLink>
                );
            case STAGES.ERROR:
                return (
                    <div>
                        <p>{i18next.t("ALERT__OTHER_ERROR_MESSAGE")}</p>
                        <button
                            onClick={() => this.setState({stage: STAGES.CODE})}>
                            <p>{i18next.t("GLOBAL__BACK")}</p>
                        </button>
                    </div>
                );
            case STAGES.LOGIN:
                return (
                   <Login goToCode={this.goOnWithCode} sponsorId={this.state.sponsorId} goBackToRegistration={this.goOnWithRegistration}> </Login>
                );
            case STAGES.SHOW_COURSE:
                return (
                    <ShowCourse codeString={this.state.codeString} goOnWithCourse={this.goOnWithCourse} language={this.state.courseLanguage} title={this.state.courseTitle} courses={this.state.courses}> </ShowCourse>
                );
            case STAGES.LANGUAGE_TEST_INFO:
                return (
                    <LanguageTestInfo startLanguageTest={this.startLanguageTest} goOnWithoutLanguageTest={this.goOnWithoutLanguageTest}  sponsorLanguageTests={this.state.sponsorLanguageTests}/>
                )
            default:
                this.setState({stage: STAGES.CODE});
        }
    }

    renderSponsorImage = () => {
        if (this.state.imgSource) {
            return (
                <div className="title-image-container">
                    <img src={this.state.imgSource} className="title-image" alt="Company Logo"/>
                </div>
            )
        }
    };

    renderTitle = () => {
        if (this.state.company && typeof this.state.company.Name !== 'undefined') {
            return (
                <h1>{this.state.company.Name.default}</h1>
            )
        } else {
            return (
                <h1>{i18next.t("TITLE__APP_TITLE", "LearnMatch Business – Registration")}</h1>
            )
        }
    };

    render() {
        if (this.state.stage === STAGES.LANGUAGE_TEST) {
            return (
                <div className="all-container">
                    <LanguageTest deviceLanguage={i18next.language} testCourse={this.state.languageTest} user={this.state.user} goOnWithTest={this.goOnAfterTest} />
                </div>
            )
        } else {
            return (
                <div className="all-container">
                    <div className="upper-third"
                         style={{ background: this.state.company ? this.state.company.PrimaryColor : "" }}>
                        <div className="title-container">
                            {
                                this.renderSponsorImage()
                            }
                            {
                                this.renderTitle()
                            }
                        </div>
                    </div>
                    <div className="lower-two-thirds">
                        {
                            this.renderContent()
                        }
                    </div>
                </div>
            )
        }
    }
}

export default App;
