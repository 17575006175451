
const live = {
    CONFIG: {
        apiKey: "AIzaSyAwIuwYxErK7qPzThkUAPUbDqbrsnis5Fw",
        authDomain: "learnmatch-b2d69.firebaseapp.com",
        databaseURL: "https://learnmatch-b2d69.firebaseio.com",
        storageBucket: "learnmatch-b2d69.appspot.com",
        projectId: "learnmatch-b2d69"
    },
    APP_NAME: "LearnMatch Business",
    COMPANY_NAME: "Vision Education",
    LOGO: require('../assets/img/demo1.jpg'),
    BRANCH_KEY: 'key_live_lpAFbGtFGwWlbAmPWkJD3ofjCDjUUPUH',
    FALLBACKLINK: "https://p6.app.link",
    CHECK_SPONSOR_COURSE_CODE_REDEMPTION: true,

    // links to agbs
    AGBLinks: {
        ar: 'https://learnmatch.net/en/terms-of-use/',
        bg: 'https://learnmatch.net/en/terms-of-use/',
        br: 'https://learnmatch.net/en/terms-of-use/',
        ca: 'https://learnmatch.net/en/terms-of-use/',
        cz: 'https://learnmatch.net/en/terms-of-use/',
        de: 'https://learnmatch.net/agb',
        el: 'https://learnmatch.net/en/terms-of-use/',
        en: 'https://learnmatch.net/en/terms-of-use/',
        er: 'https://learnmatch.net/es/condiciones-de-uso/',
        es: 'https://learnmatch.net/es/condiciones-de-uso/',
        ex: 'https://learnmatch.net/es/condiciones-de-uso/',
        fa: 'https://learnmatch.net/en/terms-of-use/',
        fr: 'https://learnmatch.net/en/terms-of-use/',
        hi: 'https://learnmatch.net/en/terms-of-use/',
        hr: 'https://learnmatch.net/en/terms-of-use/',
        hu: 'https://learnmatch.net/en/terms-of-use/',
        hy: 'https://learnmatch.net/en/terms-of-use/',
        id: 'https://learnmatch.net/en/terms-of-use/',
        it: 'https://learnmatch.net/en/terms-of-use/',
        ja: 'https://learnmatch.net/ja/terms-conditions-jpn/',
        jj: 'https://learnmatch.net/ja/terms-conditions-jpn/',
        ko: 'https://learnmatch.net/en/terms-of-use/',
        lt: 'https://learnmatch.net/en/terms-of-use/',
        nl: 'https://learnmatch.net/en/terms-of-use/',
        no: 'https://learnmatch.net/en/terms-of-use/',
        pl: 'https://learnmatch.net/en/terms-of-use/',
        pt: 'https://learnmatch.net/en/terms-of-use/',
        ro: 'https://learnmatch.net/en/terms-of-use/',
        ru: 'https://learnmatch.net/en/terms-of-use/',
        rw: 'https://learnmatch.net/en/terms-of-use/',
        sk: 'https://learnmatch.net/en/terms-of-use/',
        sl: 'https://learnmatch.net/en/terms-of-use/',
        sq: 'https://learnmatch.net/en/terms-of-use/',
        sr: 'https://learnmatch.net/en/terms-of-use/',
        sw: 'https://learnmatch.net/en/terms-of-use/',
        th: 'https://learnmatch.net/en/terms-of-use/',
        tr: 'https://learnmatch.net/tr/terms-of-use/',
        uk: 'https://learnmatch.net/en/terms-of-use/',
        ur: 'https://learnmatch.net/en/terms-of-use/',
        vi: 'https://learnmatch.net/en/terms-of-use/',
        zh: 'https://learnmatch.net/en/terms-of-use/',
        zt: 'https://learnmatch.net/en/terms-of-use/'
    },

    // links to privacy policies
    PrivacyLinks: {
        ar: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        bg: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        br: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        ca: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        cz: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        de: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        el: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        en: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        er: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        es: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        ex: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        fa: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        fr: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        hi: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        hr: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        hu: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        hy: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        id: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        it: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        ja: 'https://learnmatch.net/ja/privacy-and-policy-regulations/',
        jj: 'https://learnmatch.net/ja/privacy-and-policy-regulations/',
        ko: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        lt: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        nl: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        no: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        pl: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        pt: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        ro: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        ru: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        rw: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        sk: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        sl: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        sq: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        sr: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        sw: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        th: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        tr: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        uk: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        ur: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        vi: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        zh: 'https://learnmatch.net/unternehmen-agbadv-dsr/',
        zt: 'https://learnmatch.net/unternehmen-agbadv-dsr/'
    }
};

export default live;
