const TestingContentMap = {
    "EnglishTesting": require("../assets/content/EnglishTesting/EnglishTesting"),
    "DeutschTesting": require("../assets/content/DeutschTesting/DeutschTesting"),
    "FrenchTesting": require("../assets/content/FrenchTesting/FrenchTesting"),
    "ItalianTesting": require("../assets/content/ItalianTesting/ItalianTesting"),
    "PortugueseTesting": require("../assets/content/PortugueseTesting/PortugueseTesting"),
    "SpanishTesting": require("../assets/content/SpanishTesting/SpanishTesting"),
    "DeutschGruWoEinstufungstest": require("../assets/content/DeutschGruWoEinstufungstest/DeutschGruWoEinstufungstest.json"),
    "DeutschHotellerieTest": require("../assets/content/DeutschHotellerieTest/HotellerieTest.json"),
    "EnglishModelingTesting": require("../assets/content/EnglishModelingTesting/EnglishModelingTesting.json"),
    "EnglishGruWoEN_Einstufungstest": require("../assets/content/EnglishGruWoEN_Einstufungstest/EnglishGruwoEinstufungstest.json")
};

export default TestingContentMap;
