import 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/firestore';
import Constants from "./Constants";
import firebase from 'firebase/compat';

try{
    console.log(firebase.app());
} catch(e){
    firebase.initializeApp(Constants.CONFIG);
}

export default firebase;
