import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const ar = require('./translations/ar');
const bg = require('./translations/bg');
const br = require('./translations/br');
const cz = require('./translations/cz');
const ck = require('./translations/ck');
const de = require('./translations/de');
const el = require('./translations/el');
const en = require('./translations/en');
const er = require('./translations/er');
const es = require('./translations/es');
const ex = require('./translations/ex');
const fa = require('./translations/fa');
const fr = require('./translations/fr');
const hi = require('./translations/hi');
const hr = require('./translations/hr');
const hu = require('./translations/hu');
const hy = require('./translations/hy');
const it = require('./translations/it');
const ja = require('./translations/ja');
const km = require('./translations/km');
const ko = require('./translations/ko');
const lt = require('./translations/lt');
const nl = require('./translations/nl');
const no = require('./translations/no');
const pl = require('./translations/pl');
const pr = require('./translations/pr');
const pt = require('./translations/pt');
const ro = require('./translations/ro');
const ru = require('./translations/ru');
const rw = require('./translations/rw');
const sk = require('./translations/sk');
const sl = require('./translations/sl');
const sq = require('./translations/sq');
const sr = require('./translations/sr');
const sw = require('./translations/sw');
const tr = require('./translations/tr');
const uk = require('./translations/uk');
const ur = require('./translations/ur');
const zh = require('./translations/zh');
const zt = require('./translations/zt');
const ca = require('./translations/ca');
const bs = require('./translations/bs');
const ps = require('./translations/ps');
const jj = require('./translations/jj');
const id = require('./translations/id');
const th = require('./translations/th');
const vi = require('./translations/vi');

i18n
    .use(LanguageDetector)
    .init({
        // we init with resources
        resources: {
            ar: {
                translations: ar
            },
            bg: {
                translations: bg
            },
            br: {
                translations: br
            },
            ck:{
                translations: ck
            },
            cz: {
                translations: cz
            },
            de: {
                translations: de
            },
            el: {
                translations: el
            },
            en: {
                translations: en
            },
            er: {
                translations: er
            },
            es: {
                translations: es
            },
            ex: {
                translations: ex
            },
            fa: {
                translations: fa
            },
            fr: {
                translations: fr
            },
            hi: {
                translations: hi
            },
            hr: {
                translations: hr
            },
            hu: {
                translations: hu
            },
            hy: {
                translations: hy
            },
            id: {
                translations: id
            },
            it: {
                translations: it
            },
            ja: {
                translations: ja
            },
            km:{
                translations: km
            },
            ko: {
                translations: ko
            },
            lt: {
                translations: lt
            },
            nl: {
                translations: nl
            },
            no: {
                translations: no
            },
            pl: {
                translations: pl
            },
            pr: {
                translations: pr
            },
            pt: {
                translations: pt
            },
            ro: {
                translations: ro
            },
            ru: {
                translations: ru
            },
            rw: {
                translations: rw
            },
            sk: {
                translations: sk
            },
            sl: {
                translations: sl
            },
            sq: {
                translations: sq
            },
            sr: {
                translations: sr
            },
            sw: {
                translations: sw
            },
            th: {
                translations: th
            },
            tr: {
                translations: tr
            },
            uk: {
                translations: uk
            },
            ur: {
                translations: ur
            },
            vi: {
                translations: vi
            },
            zh: {
                translations: zh
            },
            zt: {
                translations: zt
            },
            ca: {
                translations: ca
            },
            bs: {
                translations: bs
            },
            ps: {
                translations: ps
            },
            jj: {
                translations: jj
            }
        },
        fallbackLng: 'en',
        debug: false,

        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ','
        },

        react: {
            wait: true
        }
    });

export default i18n;
